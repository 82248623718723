import Vue from 'vue'
import VueRouter from 'vue-router'
// 导航
const navPage = () => import('../views/nav.vue')
// 404
const errorpage = () => import('../views/error/errorpage.vue')
// 登录
const login = () => import(/* webpackChunkName: "login_home_welcome" */ '../views/login/login.vue')
// 主页
const home = () => import(/* webpackChunkName: "login_home_welcome" */ '../views/home/home.vue')
// 复赛表单答题大屏
const screen = () => import('../views/screen/formAnswerRank.vue')
// 全国总复活赛大屏
const finalScreenRank = () => import('../views/screen/finalScreenRank.vue')
// 欢迎页
const welcome = () => import(/* webpackChunkName: "login_home_welcome" */ '../views/home/welcome.vue')
// 下载列表
const downListHistory = () => import(/* webpackChunkName: "login_home_welcome" */ '../views/home/downListHistory.vue')
// 自然拼读订单
const ziranpindu = () => import('../views/home/ziranpindu/index.vue')
// 赛务管理-个人赛赛务管理-个人赛
const individualRaceManagement = () => import('../views/home/raceManagement/individual_management/individual_manage_homeview/individual_race_management.vue')
// 赛务管理-个人赛赛务管理-初赛
const preliminaryRaceManagement = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/preliminary_raceManagement.vue')
// 赛务管理-个人赛赛务管理-国赛报道签到
const nationalFinalsReportSignIn = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/national_finals_reportSignIn.vue')
// 赛务管理-个人赛赛务管理-复赛
const rematchRaceManagement = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/rematch_raceManagement.vue')
// 赛务管理-个人赛赛务管理-签到
const sspcnSigninDetail = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/sspcn_signinDetail.vue')
// 赛务管理-个人赛赛务管理-开始签到
const beginSignInDetail = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/beginSignInDetail.vue')
// 赛务管理-个人赛赛务管理-考量统计
const individualSchoolConsideration = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_school_dataStatistics.vue')
// 赛务管理-个人赛赛务管理-初赛-个人赛考量信息
const bSchoolConsiderations = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_school_considerations.vue')
// 赛务管理-个人赛赛务管理-初赛-个人赛考量信息-考量数据统计
const considerationDataStatistics = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/consideration_data_statistics.vue')
const signupStatisticsStatistics = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/signup_statistics.vue')
// 赛务管理-个人赛赛务管理-初赛-个人赛考量信息-个人赛考量信息详情
const bSchoolManagementDetail = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_school_management_detail.vue')
// 赛务管理-个人赛赛务管理-初赛-照片墙
const photoWallAudit = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/photo_wall_audit.vue')
// 赛务管理-个人赛赛务管理-比赛时间管理
const individualGametimeManage = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_gametime_manage.vue')
// 赛务管理-个人赛赛务管理-消息推送管理
const messagePushManagement = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_msgpush_manage.vue')
// 赛务管理-个人赛赛务管理-线下考场管理
const examinationRoomList = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/examinationRoom/examination_room_list.vue')
const examinationRoomDetails = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/examinationRoom/examination_room_details.vue')
// 赛务管理-个人赛赛务管理-网络赛考场管理
const examinationNetworkRoomList = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/examinationRoom/examination_network_room_list.vue')
// 赛务管理-个人赛赛务管理-喜报制作
const tidingsOfGladTidings = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/tidings_of_glad_tidings.vue')
// 赛务管理-团体赛赛务管理-团体赛
const teamRaceManagement = () => import('../views/home/raceManagement/team_management/team_manage_homeview/team_race_management.vue')
// 赛务管理-团体赛赛务管理-俱乐部申请信息管理
const teamClubManagement = () => import('../views/home/raceManagement/team_management/team_manage_homeview/team_club_management.vue')
// 赛务管理-团体赛赛务管理-俱乐部信息管理
const teamTeamManagement = () => import('../views/home/raceManagement/team_management/team_manage_homeview/team_team_management.vue')
// 赛务管理-团体赛赛务管理-俱乐部详情
const teamClubDetailData = () => import('../views/home/raceManagement/team_management/team_manage_homeview/team_club_detailData.vue')
// 赛务管理-团体赛赛务管理-参赛战队列表
const teamSignManagement = () => import('../views/home/raceManagement/team_management/team_manage_homeview/team_sign_management.vue')
// 赛务管理-IBC赛务管理-IBC首页
const ibcHomeManagement = () => import('../views/home/raceManagement/ibc/ibc_manage_homeview/ibc_manage_home.vue')
// 赛务管理-IBC赛务管理-IBC报名信息
const ibcRegisterInfo = () => import('../views/home/raceManagement/ibc/ibc_child_view/ibc_register_info.vue')
// 赛务管理-IBC赛务管理-IBC考场管理
const ibcExamroomInfo = () => import('../views/home/raceManagement/ibc/ibc_child_view/ibc_examroom_info.vue')
// 赛务管理-IBC赛务管理-IBC轮次管理
const ibcRoundInfo = () => import('../views/home/raceManagement/ibc/ibc_child_view/ibc_round_info.vue')
// 赛务管理-IBC赛务管理-IBC成绩设置
const ibcScoreInfo = () => import('../views/home/raceManagement/ibc/ibc_child_view/ibc_score_info.vue')
// 赛务管理-IBC赛务管理-IBC成绩查询
const ibcSearchscoreInfo = () => import('../views/home/raceManagement/ibc/ibc_child_view/ibc_searchscore_info.vue')
// 赛务管理-IBC赛务管理-IBC考场详情
const ibcExaminationDetail = () => import('../views/home/raceManagement/ibc/ibc_child_view/ibcExamination_detail.vue')
// 赛务管理-IBC赛务管理-IBC成绩设置详情
const ibcExaminationSetScore = () => import('../views/home/raceManagement/ibc/ibc_child_view/ibcExamination_set_score.vue')
// 赛务管理-营地管理-咨询列表
const campLnquiriesList = () => import('../views/home/raceManagement/camp/campLnquiriesList.vue')
// 赛务管理-营地管理-报名列表
const campSignupList = () => import('../views/home/raceManagement/camp/campSignupList.vue')
// 赛务管理-营地管理-营地列表
const campListData = () => import('../views/home/raceManagement/camp/campListData.vue')
// 赛务管理-营地管理-营地详情
const campDetailsData = () => import('../views/home/raceManagement/camp/campDetailsData.vue')
// 赛务管理-营地管理
const campManagement = () => import('../views/home/raceManagement/camp/campManagement.vue')
// 赛务管理-营地管理-营地详情-营地成员
const campCampersData = () => import('../views/home/raceManagement/camp/campCampersData.vue')

// 考务管理-个人赛考务管理-初选
const examinationHomeManage = () => import('../views/home/examination_Management/individual_examination_management/examination_home_manage/examination_home_manage.vue')
// 考务管理-个人赛考务管理-成绩查询
const scheduleIndividualCompetition = () => import('../views/home/examination_Management/individual_examination_management/schedule_competitions/schedule_individual_competition.vue')
// 考务管理-个人赛考务管理-城市赛成绩查询
const scheduleIndividualScoreList = () => import('../views/home/examination_Management/individual_examination_management/schedule_competitions/schedule_individual_scoreList.vue')
// 考务管理-个人赛考务管理-初选-答题详情
const individualAnswerResult = () => import('../views/home/examination_Management/individual_examination_management/schedule_competitions/schedule_Childview/individual_answer_result.vue')
// 考务管理-团体赛考务管理
const examinationTeamhomeManage = () => import('../views/home/examination_Management/team_examination_management/examination_teamhome_manage.vue')
// 考务管理-个人赛考务管理-免初赛管理
const dispensePreliminaryRounds = () => import('../views/home/examination_Management/individual_examination_management/schedule_competitions/schedule_dispense_competition.vue')
// 考务管理-个人赛考务管理-复赛表单答题
const scheduleFormanswerCompetition = () => import('../views/home/examination_Management/individual_examination_management/schedule_competitions/schedule_formanswer_competition.vue')
// 考务管理-个人赛考务管理-复赛表单答题解锁码
const scheduleFormcodeeCompetition = () => import('../views/home/examination_Management/individual_examination_management/schedule_competitions/schedule_formcode_competition.vue')

// 系统数据管理-系统学校基础信息-学校信息详情
const systemSchoolInfomationDetail = () => import('../views/home/system_data_Management/system_school_detail.vue')
// 系统数据管理-系统学校基础信息
const sysSchoolInfomation = () => import('../views/home/system_data_Management/system_school_management.vue')
// 系统数据管理-系统机构管理
const systemMechanismManagement = () => import('../views/home/system_data_Management/system_mechanism_management.vue')
// 系统数据管理-系统机构详情
const systemMechanismDetails = () => import('../views/home/system_data_Management/system_mechanism_details.vue')
// 系统数据管理-系统赛区管理
const systemCompetitionManagement = () => import('../views/home/system_data_Management/system_competition_management.vue')
// 系统数据管理-系统赛区详情
const systemCompetitionDetails = () => import('../views/home/system_data_Management/system_competition_details.vue')
// 系统数据管理-系统操作日志信息
const systemOperationLog = () => import('../views/home/system_data_Management/system_operation_log.vue')

// B端数据管理-首页
const bDataManagement = () => import('../views/home/b_data_Management/b_data_management.vue')
// B端数据管理-学校信息管理
const bSchoolManagement = () => import('../views/home/b_data_Management/b_school_management.vue')
// B端数据管理-赛区信息管理
const bDivisionManagement = () => import('../views/home/b_data_Management/b_division_management.vue')
// B端数据管理-赛点信息管理
const bMatchpointManagement = () => import('../views/home/b_data_Management/b_matchpoint_management.vue')

// C端数据管理-首页
const cDataManagementHome = () => import('../views/home/c_data_Management/c_data_management_home.vue')
// C端数据管理-注册信息查看
const cPlayerRegistInfo = () => import('../views/home/c_data_Management/c_player_registInfo.vue')
// C端数据管理-注册信息详情
const cPlayerRegistInfoDetails = () => import('../views/home/c_data_Management/c_player_registInfo_details.vue')
// C端数据管理-教师信息管理
const cPlayerTeacherinfo = () => import('../views/home/c_data_Management/c_player_teacherinfo.vue')

// 产品管理-字库管理
const thesaurusManagement = () => import('../views/home/product_Management/thesaurus_management.vue')
// 产品管理-课程订单管理-学校订单管理
const courseSchoolorderManagement = () => import('../views/home/product_Management/course_schoolorder_management.vue')
// 产品管理-课程订单管理-教师订单管理
const courseTeacherorderManagement = () => import('../views/home/product_Management/course_teacherorder_management.vue')
// 产品管理-开通课程订单管理
const courseOpenManagement = () => import('../views/home/product_Management/course_open_management.vue')
// 产品管理-兑换码管理
const productcodeManagement = () => import('../views/home/product_Management/productcode_management.vue')
// 产品管理-优惠券管理
const productcouponManagement = () => import('../views/home/product_Management/productcoupon_management.vue')
// 产品管理-优惠券管理-优惠券详情
const productcouponManagementDetail = () => import('../views/home/product_Management/productcoupon_management_detail.vue')
// 产品管理-订单管理
const productManagement = () => import('../views/home/product_Management/product_management.vue')
// 产品管理-首页
const productManagementHome = () => import('../views/home/product_Management/product_management_home.vue')
// 产品管理-订单管理-订单详情
const productDetailManagement = () => import('../views/home/product_Management/product_detail_management.vue')
// 产品管理-订单管理-退费订单
const productReturnManagement = () => import('../views/home/product_Management/product_return_management.vue')
// 产品管理-订单管理-订单详情
const productReturnDetail = () => import('../views/home/product_Management/product_return_detail.vue')
// 产品管理-开通产品管理
const launchProductManagement = () => import('../views/home/product_Management/launch_product_management.vue')
// 产品管理-蜂计划管理
const productPlanbeeManagement = () => import('../views/home/product_Management/product_planbee_management.vue')

// 学术管理-字库管理
const academicFontManagement = () => import('../views/home/academic_Management/academic_font_management/academic_font_management.vue')
const academicFontManagementHome = () => import('../views/home/academic_Management/academic_font_management/academic_font_management_home.vue')
// 学术管理-标签管理
const tagManagement = () => import('../views/home/tags/tags_list.vue')
// 学术管理-课程管理
const academicCourseManagement = () => import('../views/home/academic_Management/academic_course_management/academic_course_management.vue')
// 学术管理-课程管理-新增课程
const academicCourseDetail = () => import('../views/home/academic_Management/academic_course_management/academic_course_detail.vue')
// 学术管理-出题系统-单词管理
const academicWordManagement = () => import('../views/home/academic_Management/academic_font_management/academic_word_management.vue')
// 学术管理-出题系统-词库管理
const academicWordgroupManagement = () => import('../views/home/academic_Management/academic_font_management/academic_wordgroup_management.vue')
// 学术管理-出题系统-词库管理-词库详情
const academicWordgroupDetail = () => import('../views/home/academic_Management/academic_font_management/academic_wordgroup_detail.vue')
// 学术管理-出题系统-题型管理
const questionTypeSettingSystem = () => import('../views/home/academic_Management/academic_font_management/academic_questionType_management.vue')
// 学术管理-出题系统-题库管理
const questionSettingSystem = () => import('../views/home/academic_Management/academic_font_management/academic_question_management.vue')
// 学术管理-出题系统-试卷管理
const examinationPaperSystem = () => import('../views/home/academic_Management/academic_font_management/academic_examination_management.vue')
// 学术管理-出题系统-试卷管理-试卷详情
const academicSetExamination = () => import('../views/home/academic_Management/academic_font_management/academic_set_examination.vue')
// 学术管理-出题系统-蜂Talk试卷管理
const academicExaminationFtpaper = () => import('../views/home/academic_Management/academic_font_management/academic_examination_ftpaper.vue')
// 学术管理-出题系统-蜂Talk试卷详情管理
const academicExaminationFengtalk = () => import('../views/home/academic_Management/academic_font_management/academic_examination_fengtalk.vue')
// 学术管理-出题系统-蜂Talk复议单词
const academicExaminationSecond = () => import('../views/home/academic_Management/academic_font_management/academic_examination_second.vue')
// 学术管理-消息列表
const academicWordFeedback = () => import('../views/home/academic_Management/academic_font_management/academic_word_feedback.vue')
// 学术管理-裁判系统
const academicRefereeSystem = () => import('../views/home/academic_Management/academic_font_management/academic_referee_system.vue')
// 学术管理-团体赛裁判系统
const academicItsoRefereesystem = () => import('../views/home/academic_Management/academic_font_management/academic_itso_refereesystem.vue')
// 学术管理-裁判系统-考场信息
const academicIbcRefereesystem = () => import('../views/home/academic_Management/academic_font_management/academic_ibc_refereesystem.vue')
// 学术管理-裁判系统-城市赛裁判系统 academic_schdual_refereesystem
const academicSchdualRefereesystem = () => import('../views/home/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem.vue')
// 学术管理-裁判系统-城市半决选裁判系统
const academicSchdualRefereesystemHalf = () => import('../views/home/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_half.vue')
// 学术管理-裁判系统-全国总决选复活赛裁判系统
const academicSchdualRefereesystemRevive = () => import('../views/home/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_revive.vue')
// 学术管理-裁判系统-网络总裁判系统
const academicSchdualRefereesystemNet = () => import('../views/home/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_net.vue')
// 学术管理-裁判系统-全国总有组别裁判系统
const academicSchdualRefereesystemGroup = () => import('../views/home/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_group.vue')
// 学术管理-裁判系统-全国总无组别裁判系统
const academicSchdualRefereesystemFinal = () => import('../views/home/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_final.vue')
// 学术管理-裁判系统-全国总复活赛排行
const academicExaminationReviveRank = () => import('../views/home/academic_Management/academic_font_management/academic_examination_reviveRank.vue')
// 系统管理-首页
const systemManagementHome = () => import('../views/home/system_Management/system_management_home.vue')
// 系统管理-用户列表
const systemUserManagement = () => import('../views/home/system_Management/system_management_user.vue')
// 系统管理-部门管理
const systemManagementSection = () => import('../views/home/system_Management/system_management_section.vue')
// 系统管理-岗位管理
const systemManagementPosition = () => import('../views/home/system_Management/system_management_position.vue')
// 系统管理-权限管理
const systemManagementAuthority = () => import('../views/home/system_Management/system_management_authority.vue')
// 系统管理-字典管理
const systemManagementDictionary = () => import('../views/home/system_Management/system_management_dictionary.vue')
// 系统管理-小程序banner管理
const systemManagementBanner = () => import('../views/home/system_Management/system_management_banner.vue')
// 系统管理-日志列表
const systemManagementLogs = () => import('../views/home/system_Management/system_management_logs.vue')
// 拼词之星-首页
const pczxIndex = () => import('../views/home/pczx_management/pczx_index.vue')
// 拼词之星-房间管理
const pczxRoomManage = () => import('../views/home/pczx_management/pczx_room_manage.vue')
// 拼词之星-签到管理
const pczxSigninManage = () => import('../views/home/pczx_management/pczx_signin_manage.vue')

const aiCompetitionToBSetting = () => import('../views/home/AICompetition/toBSetting/settingList.vue')
const aiCompetitionToBSettingEdit = () => import('../views/home/AICompetition/toBSetting/settingEdit.vue')

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/navPage'
    },
    {
      path: '/navPage',
      component: navPage
    },
    {
      path: '*',
      name: '404',
      component: errorpage
    },
    {
      path: '/login',
      component: login
    },
    {
      path: '/screen/home',
      component: screen
    },
    {
      path: '/screen/finalScreenRank',
      component: finalScreenRank
    },
    {
      path: '/individual_racing_schdules/beginSignInDetail',
      component: beginSignInDetail
    },
    {
      path: '/home',
      component: home,
      redirect: '/welcome',
      children: [
        {
          path: '/welcome',
          component: welcome
        },
        {
          path: '/ziranpindu/index',
          component: ziranpindu
        },
        {
          path: '/home/downListHistory',
          component: downListHistory
        },
        {
          path: '/system_data_Management/system_operation_log',
          component: systemOperationLog
        },
        {
          path: '/c_data_Management/c_data_management_home',
          component: cDataManagementHome
        },
        {
          path: '/c_data_Management/c_player_registInfo',
          component: cPlayerRegistInfo
        },
        {
          path: '/c_data_Management/c_player_registInfo_details',
          component: cPlayerRegistInfoDetails
        },
        {
          path: '/c_data_Management/c_player_teacherinfo',
          component: cPlayerTeacherinfo
        },
        {
          path: '/system_data_Management/system_school_management',
          component: sysSchoolInfomation
        },
        {
          path: '/system_data_Management/system_school_detail',
          component: systemSchoolInfomationDetail
        },
        {
          path: '/system_data_Management/system_mechanism_management',
          component: systemMechanismManagement
        },
        {
          path: '/system_data_Management/system_mechanism_details',
          component: systemMechanismDetails
        },
        {
          path: '/system_data_Management/system_competition_management',
          component: systemCompetitionManagement
        },
        {
          path: '/system_data_Management/system_competition_details',
          component: systemCompetitionDetails
        },
        {
          path: '/b_data_Management/b_data_management',
          component: bDataManagement
        },
        {
          path: '/b_data_Management/b_school_management',
          component: bSchoolManagement
        },
        {
          path: '/b_data_Management/b_division_management',
          component: bDivisionManagement
        },
        {
          path: '/b_data_Management/b_matchpoint_management',
          component: bMatchpointManagement
        },
        {
          path: '/individual_manage_homeview/individual_race_management',
          component: individualRaceManagement
        },
        {
          path: '/individual_racing_tool/individual_school_considerations',
          component: bSchoolConsiderations
        },
        {
          path: '/individual_racing_tool/data_statistics',
          component: considerationDataStatistics
        },
        {
          path: '/individual_racing_tool/signup_statistics',
          component: signupStatisticsStatistics
        },
        {
          path: '/individual_racing_tool/individual_school_dataStatistics',
          component: individualSchoolConsideration
        },
        {
          path: '/individual_racing_tool/individual_school_management_detail',
          component: bSchoolManagementDetail
        }, {
          path: '/individual_racing_tool/photo_wall_audit',
          component: photoWallAudit
        },
        {
          path: '/individual_racing_tool/individual_gametime_manage',
          component: individualGametimeManage
        },
        {
          path: '/individual_racing_tool/individual_msgpush_manage',
          component: messagePushManagement
        },
        {
          path: '/individual_racing_tool/examination_room_list',
          component: examinationRoomList
        },
        {
          path: '/individual_racing_tool/examination_network_room_list',
          component: examinationNetworkRoomList,
          meta: {
            keepAlive: true // 缓存，保存状态，用于页面返回不刷新
          }
        },
        {
          path: '/individual_racing_tool/examination_room_details',
          component: examinationRoomDetails
        },
        {
          path: '/individual_racing_tool/tidings_of_glad_tidings',
          component: tidingsOfGladTidings
        },
        {
          path: '/product_Management/thesaurus_management',
          component: thesaurusManagement
        },
        {
          path: '/product_Management/course_open_management',
          component: courseOpenManagement
        },
        {
          path: '/product_Management/product_management',
          component: productManagement,
          meta: {
            keepAlive: true, // 缓存，保存状态，用于页面返回不刷新
            isPush: true
          }
        },
        {
          path: '/product_Management/product_management_home',
          component: productManagementHome
        },
        {
          path: '/product_Management/productcode_management',
          component: productcodeManagement
        },
        {
          path: '/product_Management/productcoupon_management',
          component: productcouponManagement
        },
        {
          path: '/product_Management/productcoupon_management_detail',
          component: productcouponManagementDetail
        },
        {
          path: '/product_Management/course_schoolorder_management',
          component: courseSchoolorderManagement
        },
        {
          path: '/product_Management/course_teacherorder_management',
          component: courseTeacherorderManagement
        },
        {
          path: '/product_Management/product_detail_management',
          component: productDetailManagement
        },
        {
          path: '/product_Management/product_return_management',
          component: productReturnManagement,
          meta: {
            keepAlive: true // 缓存，保存状态，用于页面返回不刷新
          }
        },
        {
          path: '/product_Management/product_return_detail',
          component: productReturnDetail
        },
        {
          path: '/product_Management/launch_product_management',
          component: launchProductManagement
        },
        {
          path: '/product_Management/product_planbee_management',
          component: productPlanbeeManagement
        },
        {
          path: '/individual_racing_schedules/preliminary_raceManagement',
          component: preliminaryRaceManagement
        },
        {
          path: '/individual_racing_schedules/national_finals_reportSignIn',
          component: nationalFinalsReportSignIn
        },
        {
          path: '/individual_racing_schedules/rematch_raceManagement',
          component: rematchRaceManagement
        },
        {
          path: '/individual_racing_schdules/sspcn_signinDetail',
          component: sspcnSigninDetail
        },
        {
          path: '/examination_Management/individual_examination_management/examination_home_manage/examination_home_manage',
          component: examinationHomeManage
        },
        {
          path: '/schedule_competitions/schedule_dispense_competition',
          component: dispensePreliminaryRounds
        },
        {
          path: '/schedule_competitions/schedule_formanswer_competition',
          component: scheduleFormanswerCompetition
        },
        {
          path: '/schedule_competitions/schedule_formcode_competition',
          component: scheduleFormcodeeCompetition
        },
        {
          path: '/examination_Management/individual_examination_management/schedule_competitions/schedule_individual_competition',
          component: scheduleIndividualCompetition
        }, {
          path: '/examination_Management/individual_examination_management/schedule_competitions/schedule_individual_scoreList',
          component: scheduleIndividualScoreList
        },
        {
          path: '/examination_Management/individual_examination_management/schedule_competitions/schedule_Childview/individual_answer_result',
          component: individualAnswerResult,
          name: 'individualAnswerResult'
        },
        {
          path: '/examination_Management/team_examination_management/examination_teamhome_manage',
          component: examinationTeamhomeManage
        },
        {
          path: '/raceManagement/team_management/team_race_management',
          component: teamRaceManagement
        },
        {
          path: '/raceManagement/team_management/team_club_management',
          component: teamClubManagement
        },
        {
          path: '/raceManagement/team_management/team_team_management',
          component: teamTeamManagement
        },
        {
          path: '/team_management/team_manage_homeview/team_club_detailData',
          component: teamClubDetailData
        },
        {
          path: '/team_management/team_manage_homeview/team_sign_management',
          component: teamSignManagement
        },
        {
          path: '/academic_Management/academic_font_management/academic_word_list',
          component: academicWordManagement
        },
        {
          path: '/academic_Management/academic_font_management/academic_wordgroup_management',
          component: academicWordgroupManagement
        },
        {
          path: '/academic_Management/academic_font_management/academic_wordgroup_detail',
          component: academicWordgroupDetail
        },
        {
          path: '/academic_Management/academic_font_management/academic_font_management',
          component: academicFontManagement
        },
        {
          path: '/academic_Management/academic_font_management/academic_font_management_home',
          component: academicFontManagementHome
        },
        {
          path: '/academic_Management/academic_font_management/academic_questionType_management',
          component: questionTypeSettingSystem
        },
        {
          path: '/academic_Management/academic_font_management/academic_examination_ftpaper',
          component: academicExaminationFtpaper
        },
        {
          path: '/academic_Management/academic_font_management/academic_examination_fengtalk',
          component: academicExaminationFengtalk
        },
        {
          path: '/academic_Management/academic_font_management/academic_examination_second',
          component: academicExaminationSecond
        },
        {
          path: '/academic_Management/academic_font_management/academic_question_management',
          component: questionSettingSystem
        },
        {
          path: '/academic_Management/academic_font_management/academic_examination_management',
          component: examinationPaperSystem,
          meta: {
            keepAlive: true // 缓存，保存状态，用于页面返回不刷新
          }
        },
        {
          path: '/academic_Management/academic_font_management/academic_set_examination',
          component: academicSetExamination
        },
        {
          path: '/academic_Management/tags/list',
          component: tagManagement
        },
        {
          path: '/academic_Management/academic_course_management/academic_course_management',
          component: academicCourseManagement,
          meta: {
            keepAlive: true // 缓存，保存状态，用于页面返回不刷新
          }
        },
        {
          path: '/academic_Management/academic_course_management/academic_course_detail',
          component: academicCourseDetail
        },
        {
          path: '/academic_Management/academic_font_management/academic_word_feedback',
          component: academicWordFeedback
        },
        {
          path: '/academic_Management/academic_font_management/academic_referee_system',
          component: academicRefereeSystem
        },
        {
          path: '/academic_Management/academic_font_management/academic_itso_refereesystem',
          component: academicItsoRefereesystem
        },
        {
          path: '/academic_Management/academic_font_management/academic_ibc_refereesystem',
          component: academicIbcRefereesystem
        },
        {
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem',
          component: academicSchdualRefereesystem
        },
        {
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_h',
          component: academicSchdualRefereesystemHalf
        },
        {
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_revive',
          component: academicSchdualRefereesystemRevive
        },
        {
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_net',
          component: academicSchdualRefereesystemNet
        },
        {
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_group',
          component: academicSchdualRefereesystemGroup
        },
        {
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_final',
          component: academicSchdualRefereesystemFinal
        },
        {
          path: '/chtyFinaalRefereesystem/academic_examination_reviveRank',
          component: academicExaminationReviveRank
        },
        {
          path: '/system_Management/system_management_home',
          component: systemManagementHome
        },
        {
          path: '/system_Management/system_management_user',
          component: systemUserManagement
        },
        {
          path: '/system_Management/system_management_section',
          component: systemManagementSection
        },
        {
          path: '/system_Management/system_management_position',
          component: systemManagementPosition
        },
        {
          path: '/system_Management/system_management_authority',
          component: systemManagementAuthority
        },
        {
          path: '/system_Management/system_management_dictionary',
          component: systemManagementDictionary
        },
        {
          path: '/system_Management/system_management_banner',
          component: systemManagementBanner
        },
        {
          path: '/system_Management/system_management_logs',
          component: systemManagementLogs
        },
        {
          path: '/raceManagement/ibcHomeManagement',
          component: ibcHomeManagement
        }, {
          path: '/raceManagement/ibc/ibc_register_info',
          component: ibcRegisterInfo
        },
        {
          path: '/raceManagement/ibc/ibc_examroom_info',
          component: ibcExamroomInfo
        },
        {
          path: '/raceManagement/ibc/ibc_round_info',
          component: ibcRoundInfo
        },
        {
          path: '/raceManagement/ibc/ibc_score_info',
          component: ibcScoreInfo
        },
        {
          path: '/raceManagement/ibc/ibc_searchscore_info',
          component: ibcSearchscoreInfo
        },
        {
          path: '/raceManagement/ibc/ibcExamination_detail',
          component: ibcExaminationDetail
        },
        {
          path: '/raceManagement/ibc/ibcExamination_set_score',
          component: ibcExaminationSetScore
        },
        {
          path: '/raceManagement/camp/campLnquiriesList',
          component: campLnquiriesList
        },
        {
          path: '/raceManagement/campManagement',
          component: campManagement
        },
        {
          path: '/raceManagement/camp/campCampersData',
          component: campCampersData
        },
        {
          path: '/raceManagement/camp/campSignupList',
          component: campSignupList
        },
        {
          path: '/raceManagement/camp/campListData',
          component: campListData
        },
        {
          path: '/raceManagement/camp/campDetailsData',
          component: campDetailsData
        },
        {
          path: '/pczx_room_manage',
          component: pczxRoomManage
        },
        {
          path: '/pczx_signin_manage',
          component: pczxSigninManage
        },
        {
          path: '/pczx_index',
          component: pczxIndex
        },
        {
          path: '/AICompetition/toBSetting/list',
          component: aiCompetitionToBSetting
        },
        {
          path: '/AICompetition/toBSetting/edit',
          component: aiCompetitionToBSettingEdit
        }
      ]
    }
  ]
})

// 挂载导航路由守卫
router.beforeEach((to, from, next) => {
  const whiteList = [
    '/navPage',
    '/login',
    '/screen/home',
    '/individual_racing_schdules/beginSignInDetail'
  ]
  if (whiteList.includes(to.path)) return next()
  if (!localStorage.getItem('Authorization')) return next('/login')
  next()
})

export default router
