<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>产品管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>退费管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>退费申请筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="80px"
                ref="queryFormRef"
            >
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择赛季"
                                style="width: 100%"
                                v-model="queryForm.season"
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <el-col :span="6">
                        <el-form-item label="用户名" prop="userName">
                            <el-input
                                v-model="queryForm.userName"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="账号" prop="phone">
                            <el-input
                                v-model="queryForm.phone"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="赛区" prop="districtId">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.districtId"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="审批进度" prop="processStatus">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.processStatus"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in product_processStatus"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="订单编号" prop="orderNo">
                            <el-input
                                v-model="queryForm.orderNo"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款状态" prop="orderStatus">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.partRefund"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_approvalType"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="9">
            <el-form-item label="申请时间" prop="time">
              <el-date-picker
                v-model="queryForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col> -->
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="searchGetDataList"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" style="width: 100%" @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="12">
                    <div class="list_title">退费申请列表</div>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <el-button
                        type="primary"
                        plain
                        @click="downloadInfoList"
                        icon="el-icon-download"
                        >批量导出</el-button
                    >
                </el-col>
            </el-row>
            <el-tabs
                type="border-card"
                v-model="recordStatusData"
                @tab-click="returnTypeChengeClick"
            >
                <el-tab-pane
                    v-for="(item, index) in dict_refundState"
                    :key="index"
                    :label="item.dictLabel"
                    :name="item.dictValue"
                >
                </el-tab-pane>
                <el-table
                    header-cell-class-name="tableHeaderStyle"
                    :data="dataList"
                    border
                >
                    <!-- stripe
      @selection-change="handleSelectionChange" -->
                    <el-table-column
                        label="订单号"
                        prop="orderNo"
                    ></el-table-column>
                    <el-table-column
                        label="申请时间"
                        prop="createTime"
                    ></el-table-column>
                    <el-table-column
                        label="赛区名称"
                        prop="districtName"
                    ></el-table-column>
                    <el-table-column label="学校名称" prop="schoolName">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sysUserInfo.schoolName }}</span>
                        </template></el-table-column
                    >
                    <el-table-column
                        label="申请账号"
                        prop="phone"
                    ></el-table-column>
                    <el-table-column
                        label="申请人"
                        prop="userName"
                    ></el-table-column>
                    <el-table-column
                        label="订单名称"
                        prop="orderName"
                    ></el-table-column>
                    <el-table-column label="支付金额" prop="orderMoney">
                        <template slot-scope="scope">
                            <span style="color: red">{{
                                scope.row.orderMoney
                            }}</span>
                        </template></el-table-column
                    >
                    <el-table-column label="退费金额" prop="refundMoney">
                        <template slot-scope="scope">
                            <span style="color: green">{{
                                scope.row.refundMoney
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="申请原因"
                        prop="reason"
                    ></el-table-column>
                    <el-table-column
                        label="审批状态"
                        prop="recordStatus"
                        :formatter="refundStateFormatter"
                    ></el-table-column>
                    <el-table-column
                        label="审批等级"
                        prop="processStatus"
                        :formatter="processStatusFormatter"
                    ></el-table-column>
                    <el-table-column label="退款时间" prop="updateTime">
                        <template slot-scope="scope">
                            <span v-if="scope.row.recordStatus === 4">
                                {{ scope.row.refundProcess.updateTime }}
                            </span>
                            <span v-else> - </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                icon="el-icon-s-order"
                                size="small"
                                @click="goDetail(scope.row.id)"
                                >查看详情</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-tabs>

            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <el-dialog title="处理审批" :visible.sync="batchApprovalDialogVisable">
            <div>
                审批意见:
                <el-input v-model="batchApproval.message"></el-input>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="approval(1)" type="success">同 意</el-button>
                <el-button @click="approval(2)" type="danger">驳 回</el-button>
                <el-button @click="batchApprovalDialogVisable = false"
                    >取 消</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { queryDistrictPage, orderRefundReviewList } from '@/http/api'
export default {
  data () {
    return {
      recordStatusData: '',
      queryForm: {
        phone: '',
        districtId: '',
        processStatus: undefined,
        recordStatus: undefined,
        time: '',
        name: '',
        pageNum: 1,
        pageSize: 10
      },
      dict_approvalType: [
        {
          dictLabel: '部分退款',
          dictValue: true
        },
        {
          dictLabel: '全部退款',
          dictValue: false
        }
      ],
      total: 0,
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_district: [],
      product_processStatus: this.$userInfo.product_processStatus(),
      order_status: this.$userInfo.orderStatus(),
      dict_refundState: this.$userInfo.dict_refundState(),
      dataList: [],
      batchApproval: {
        id: [],
        recordStatus: undefined,
        message: ''
      },
      batchApprovalDialogVisable: false
    }
  },
  created () {
    this.getDistrictList()
  },
  watch: {
    '$route' (to, from) {
      console.log('to', to)
      console.log('from', from)
      // 当路由从PageB返回PageA时，to是PageA，from是PageB
      if (from.path === '/product_Management/product_return_detail' && to.path === '/product_Management/product_return_management') {
        this.getDataList()
      }
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 退费订单状态点击事件
    returnTypeChengeClick (tab, event) {
      var selectIndex = Number(tab.index)
      console.log('selectIndex', selectIndex)
      if (selectIndex === 0) {
        delete this.queryForm.recordStatus
      } else {
        this.queryForm.recordStatus = this.dict_refundState[selectIndex].dictValue
      }
      this.getDataList('search')
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
        this.getDataList('search')
      }).catch((err) => {
        console.log('err', err)
      })
    },
    searchGetDataList () {
      this.getDataList('search')
    },
    async getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      orderRefundReviewList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    timeStampFormatter (row, col, cell) {
      return this.$xcUtils.timestamp2Date(parseInt(cell))
    },
    refundStateFormatter (row, col, cell) {
      var temp = '未设置'
      for (let index = 0; index < this.dict_refundState.length; index++) {
        const element = this.dict_refundState[index]
        if (row.recordStatus + '' === element.dictValue) {
          temp = element.dictLabel
        }
      }
      return temp
    },
    processStatusFormatter (row, col, cell) {
      var temp = '未设置'
      if (row.recordStatus === 4) {
        temp = '审批结束'
      } else {
        for (let index = 0; index < this.product_processStatus.length; index++) {
          const element = this.product_processStatus[index]
          if (row.refundProcess.processStatus === element.dictValue) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    goDetail (refundId) {
      this.$router.push(
        {
          path: '/product_Management/product_return_detail',
          query: {
            refundId: refundId
          }
        }
      )
    },
    handleSelectionChange (data) {
      this.batchApproval = {
        id: [],
        recordStatus: undefined,
        message: ''
      }
      data.forEach(element => {
        this.batchApproval.id.push(element.id)
        this.batchApproval.recordStatus = element.recordStatus
      })
    },
    showApprovalDialog () {
      if (this.batchApproval.id.length > 0) {
        this.batchApprovalDialogVisable = true
      } else {
        this.$message.warning('请至少选择一个申请单')
      }
    },
    async approval (flag) {
      if (flag === 2 && this.batchApproval.message === '') {
        return this.$message.warning('驳回时需要填写审批意见')
      }
      this.batchApprovalDialogVisable = false
      this.batchApproval.result = flag
      const { data: res } = await this.$http.post('/erp/refund/updateRefundProcess', this.batchApproval)
      this.$curUtils.closeLoading(this)
      console.log('approval', res.data)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('审批成功')
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    downloadInfoList () {
      var downLoadUrl = this.$env.baseIP + 'refundRecord/export'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', this.queryForm)
    }
  }
}
</script>

<style>
</style>
