<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>产品管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>退费管理</el-breadcrumb-item>
            <el-breadcrumb-item>退费详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>退费订单详情</span>
                </el-col>
            </el-row>
            <el-form
                :model="orderDetailForm"
                class="filter_Form"
                label-width="80px"
            >
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-input
                                v-model="orderDetailForm.season"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="订单名称" prop="orderName">
                            <el-input
                                v-model="orderDetailForm.orderName"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="订单号" prop="orderNo">
                            <el-input
                                v-model="orderDetailForm.orderNo"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="购买时间" prop="orderPayTime">
                            <el-input
                                v-model="orderDetailForm.orderPayTime"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="申请时间" prop="createTime">
                            <el-input
                                v-model="orderDetailForm.createTime"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="账号" prop="phone">
                            <el-input
                                v-model="orderDetailForm.phone"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="姓名" prop="userName">
                            <el-input
                                v-model="orderDetailForm.userName"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学校" prop="schoolName">
                            <el-input
                                v-model="orderDetailForm.schoolName"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="年级" prop="grade">
                            <el-input
                                v-model="orderDetailForm.grade"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="订单金额" prop="orderMoney">
                            <el-input
                                v-model="orderDetailForm.orderMoney"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="支付金额" prop="orderPayMoney">
                            <el-input
                                v-model="orderDetailForm.orderPayMoney"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="退款金额" prop="refundMoney">
                            <el-input
                                v-model="orderDetailForm.refundMoney"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="orderDetailForm.courierNum">
                        <el-form-item label="快递单号" prop="courierNum">
                            <el-input
                                v-model="orderDetailForm.courierNum"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="orderDetailForm.courierCompany">
                        <el-form-item label="快递公司" prop="courierCompany">
                            <el-input
                                v-model="orderDetailForm.courierCompany"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="申请原因" prop="reason">
                            <el-input
                                v-model="orderDetailForm.reason"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="orderDetailForm.picUrl">
                        <el-form-item label="退费图片" prop="picUrl">
                            <el-image
                                style="width: 100px; height: 100px"
                                :src="orderDetailForm.picUrl"
                                fit="contain"
                                :preview-src-list="[orderDetailForm.picUrl]"
                            >
                            </el-image>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="退费状态" prop="partRefund">
                            <el-select
                                placeholder="请选择"
                                disabled
                                v-model="orderDetailFormData.partRefund"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_approvalType"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>退费审批详情</span>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="applyList"
                border
                stripe
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column
                    label="审批部门"
                    prop="levelName"
                    width="150px"
                ></el-table-column>
                <el-table-column
                    label="审批人"
                    prop="userName"
                    width="150px"
                ></el-table-column>
                <el-table-column label="审批状态" prop="result" width="100px">
                    <template slot-scope="scope">
                        <el-tag
                            v-if="scope.row.result === -1"
                            type="warning"
                            size="mini"
                            >未审批</el-tag
                        >
                        <el-tag
                            v-else-if="scope.row.result === 1"
                            type="success"
                            size="mini"
                            >审批通过</el-tag
                        >
                        <el-tag
                            v-else-if="scope.row.result === 2"
                            type="danger"
                            size="mini"
                            >审批未通过</el-tag
                        >
                        <span v-else>未知</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="审批时间"
                    prop="reviewDate"
                    width="200px"
                ></el-table-column>
                <el-table-column
                    label="审批意见"
                    prop="message"
                ></el-table-column>
                <el-table-column label="操作" width="200px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-s-check"
                            size="small"
                            :disabled="!scope.row.resulting"
                            @click="showApprovalDialog()"
                            >审批</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            :disabled="!scope.row.resulting"
                            @click="showChangePriceDialog(scope.row)"
                            >价格修改</el-button
                        >
                        <el-button
                        v-if="scope.row.level === 1"
                            type="text"
                            icon="el-icon-refresh-left"
                            size="small"
                            @click="withdrawClicked()"
                            >撤回</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
            title="处理审批"
            :visible.sync="approvalDialogVisible"
            width="40%"
        >
            <div>
                审批意见:
                <el-input
                    type="textarea"
                    v-model="approvalMessage"
                    placeholder="请输入审批意见"
                    style="margin-top: 15px"
                ></el-input>
            </div>
            <div style="margin-top: 15px">
                退费状态:
                <el-select
                    placeholder="请选择"
                    v-model="orderDetailFormData.partRefund"
                    style="width: 100%; margin-top: 10px"
                >
                    <el-option
                        v-for="item in dict_approvalType"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                    ></el-option>
                </el-select>
            </div>
            <div style="margin-top: 10px; font-size: 12px; color: red">
                （注意*部分退款只退费，资格不变/全部退款费用退还，资格删除）
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="approval(1)" type="success">同 意</el-button>
                <el-button @click="approval(2)" type="danger">驳 回</el-button>
                <el-button @click="approvalDialogVisible = false"
                    >取 消</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            title="修改退款价格"
            :visible.sync="changePriceDialogVisible"
            width="40%"
            @close="orderPriceDialogClose"
        >
            <el-form
                :model="orderPriceModel"
                label-width="140px"
                ref="orderPriceModelFormRef"
                :rules="orderPriceModelRules"
                style="width: 100%"
            >
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="退款金额" prop="refundMoney">
                            <el-input
                                v-model="orderPriceModel.refundMoney"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="退款原因" prop="message">
                            <el-input
                                v-model="orderPriceModel.message"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="changeOrderPrice" type="success"
                    >确 定</el-button
                >
                <el-button @click="changePriceDialogVisible = false"
                    >取 消</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { orderRefundReviewInfo, getSysUserInfo, refundRecordReview, changeOrderPrice, refundRecordBackNode } from '@/http/api'
export default {
  data () {
    return {
      orderPriceModelRules: {
        refundMoney: [{ required: true, message: '请输入', trigger: 'blur' }],
        message: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      changePriceDialogVisible: false,
      orderDetailForm: {},
      refundId: this.$route.query.refundId,
      applyList: [],
      approvalDialogVisible: false,
      approvalMessage: '',
      dict_approvalType: [
        {
          dictLabel: '部分退款',
          dictValue: true
        },
        {
          dictLabel: '全部退款',
          dictValue: false
        }
      ],
      approvalType: null, // true: 部分退费 false: 全部退费
      approvalData: {},
      orderPriceModel: {
        message: '',
        refundMoney: null
      }
    }
  },
  created () {
    this.getOrderDetail()
    // this.getApplyList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 撤回审批
    withdrawClicked () {
      this.$confirm('是否确认撤回至赛区审核?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.refundRecordBackNode()
      }).catch(() => { })
    },
    refundRecordBackNode () {
      refundRecordBackNode({ id: this.refundId }).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.getOrderDetail()
        } else {
          return this.$message.error(res.msg)
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    getOrderDetail () {
      orderRefundReviewInfo({ id: this.refundId }).then((res) => {
        if (res.code === 200) {
          this.orderDetailFormData = res.data
          if (res.data.refundProcessRecords) {
            this.applyList = res.data.refundProcessRecords
          }
          this.getUserInfo(res.data.userId)
        } else {
          return this.$message.error(res.msg)
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取用户信息
    getUserInfo (userId) {
      getSysUserInfo({ userId: userId }).then((res) => {
        console.log('getSysUserInfo', res)
        this.orderDetailFormData.schoolName = res.data.schoolName
        this.orderDetailFormData.grade = res.data.grade
        this.orderDetailForm = this.orderDetailFormData
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    showApprovalDialog () {
      this.approvalData = {
        refundRecordId: this.orderDetailFormData.id
      }
      this.approvalDialogVisible = true
    },
    showChangePriceDialog (rowData) {
      this.orderPriceModel.refundProcessRecordId = rowData.id
      this.changePriceDialogVisible = true
    },
    orderPriceDialogClose () {
      this.$refs.orderPriceModelFormRef.resetFields()
    },
    changeOrderPrice () {
      this.$refs.orderPriceModelFormRef.validate(async valid => {
        if (!valid) return
        changeOrderPrice(this.orderPriceModel).then((res) => {
          if (res.code === 200) {
            this.changePriceDialogVisible = false
            this.$message.success('修改成功')
            this.getOrderDetail()
          } else {
            this.$message.error(res.msg)
          }
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    approval (flag) {
      if (flag === 2 && this.approvalMessage === '') {
        return this.$message.warning('驳回时需要填写审批意见')
      }
      this.approvalData.partRefund = this.orderDetailFormData.partRefund
      this.approvalData.refuseReason = this.approvalMessage
      this.approvalData.status = flag
      refundRecordReview(this.approvalData).then((res) => {
        if (res.code === 200) {
          this.$message.success('审批成功')
          this.approvalDialogVisible = false
          this.getOrderDetail()
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    timeStampFormatter (row, col, cell) {
      return cell === '' ? '' : this.$xcUtils.timestamp2Date(parseInt(cell))
    }
  }
}
</script>

<style>
</style>
