import request from '../http/request'
// 获取字典数据
export function getDictList (params) {
  return request({
    url: '/dict/getDictList', // 请求地址
    method: 'GET', // 请求方式
    params // 参数
  })
}
// 发送短信验证码
export function onSendSms (data) {
  return request({
    url: '/common/smsVerificationCode', // 请求地址
    method: 'POST', // 请求方式
    data // 参数
  })
}
// 登录
export function chnEngLoginRequest (data) {
  return request({
    url: '/mobileLoginErp',
    method: 'POST',
    data
  })
}
// 退出登录
export function loginOutRequest (params) {
  return request({
    url: '/loginOut',
    method: 'GET',
    params
  })
}
// 获取用户信息列表
export function getSysUserList (params) {
  return request({
    url: '/erpUser/getSysUserList',
    method: 'GET',
    params
  })
}
// 获取用户详情信息
export function getSysUserInfo (params) {
  return request({
    url: '/erpUser/getSysUserInfo',
    method: 'GET',
    params
  })
}
// 获取赛区信息列表（无限制）
export function queryAllDistrictPage (params) {
  return request({
    url: '/erp/district/all',
    method: 'GET',
    params
  })
}
// 获取赛区信息列表
export function queryDistrictPage (params) {
  return request({
    url: '/erp/district/queryDistrictPage',
    method: 'GET',
    params
  })
}
// 获取申请考量的学校列表
export function getApplySchool (params) {
  return request({
    url: '/paper/getApplySchool',
    method: 'GET',
    params
  })
}

// 获取学校省市区
export function getAllAreaList (params) {
  return request({
    url: '/common/getAllAreaList',
    method: 'GET',
    params
  })
}

// 查询学校列表
export function getSchoolList (params) {
  return request({
    url: '/school/getSysSchoolList',
    method: 'GET',
    params
  })
}
// 删除学校数据
export function deleteSchoolData (data) {
  return request({
    url: '/school/delete',
    method: 'DELETE',
    data
  })
}

// 查询机构列表
export function getSysInstitutionList (params) {
  return request({
    url: '/institution/getSysSchoolList',
    method: 'GET',
    params
  })
}
// 获取地址列表
export function getSysUserAddressListByUserId (params) {
  return request({
    url: '/address/getSysUserAddressListByUserId',
    method: 'GET',
    params
  })
}

// 根据省市区查询学校
export function getSchoolListWithCity (params) {
  return request({
    url: '/school/getSchoolList',
    method: 'GET',
    params
  })
}
// 保存用户信息
export function saveSysUserAll (data) {
  return request({
    url: '/erpUser/saveSysUserAll',
    method: 'POST',
    data
  })
}
// 新增用户信息
export function saveSysUserByPhone (data) {
  return request({
    url: '/erpUser/saveSysUserByPhone',
    method: 'POST',
    data
  })
}
// 新增用户信息多条
export function saveSysUserBatch (data) {
  return request({
    url: '/erpUser/saveSysUserBatch',
    method: 'POST',
    data
  })
}
// erp 查询学校列表
export function getSysSchoolList (params) {
  return request({
    url: '/school/getSysSchoolList',
    method: 'GET',
    params
  })
}
// 保存学校信息
export function addSysSchool (data) {
  return request({
    url: '/school/addSysSchool',
    method: 'POST',
    data
  })
}
// 获取学校联系老师
export function getSchoolContactInfo (schoolId) {
  return request({
    url: `/school/schoolInfo/${schoolId}`,
    method: 'GET'
  })
}
// 获取机构联系老师
export function getInstitutionlInfo (institutionlId) {
  return request({
    url: `/institution/institutionlInfo/${institutionlId}`,
    method: 'GET'
  })
}
// 保存机构信息
export function addSysInstitution (data) {
  return request({
    url: '/institution/addSysSchool',
    method: 'POST',
    data
  })
}

// 查询学校审核列表
export function getApproveSchoolList (params) {
  return request({
    url: '/school/getApproveSchoolList',
    method: 'GET',
    params
  })
}
// 批量生成学校参赛码
export function createSchoolUniqueCode (params) {
  return request({
    url: '/school/createSchoolUniqueCode',
    method: 'GET',
    params
  })
}
// 学校审核状态操作
export function approveSchoolTeacher (data) {
  return request({
    url: '/school/approveSchoolTeacher',
    method: 'POST',
    data
  })
}
// 查询字库相关列表
export function queryOpenProductList (params) {
  return request({
    url: '/openProduct/queryOpenProductList',
    method: 'GET',
    params
  })
}
// 获取小程序轮播图
export function bannerList (data) {
  return request({
    url: '/banner/list',
    method: 'POST',
    data
  })
}
// 删除小程序banner
export function bannerDelete (data) {
  return request({
    url: '/banner/delete',
    method: 'DELETE',
    data
  })
}

// 保存用户开通字库
export function addOpenProductList (data) {
  return request({
    url: '/openProduct/addOpenProductList',
    method: 'POST',
    data
  })
}
// 查询学校试卷申请列表
export function getPaperApplyList (params) {
  return request({
    url: '/paper/getPaperApplyList',
    method: 'GET',
    params
  })
}
// 审核学校试卷申请
export function processPaperApply (data) {
  return request({
    url: '/paper/processPaperApply',
    method: 'POST',
    data
  })
}
// 获取学校试卷申请详情
export function queryPersonalPaperApplyInfo (params) {
  return request({
    url: '/paper/queryPersonalPaperApplyInfo',
    method: 'GET',
    params
  })
}
// 审核学校试卷申请
export function paperApplyExpressDelivery (data) {
  return request({
    url: '/paper/paperApplyExpressDelivery',
    method: 'POST',
    data
  })
}
// 变更试卷状态
export function updatePaperStatusDTO (data) {
  return request({
    url: '/paper/updatePaperStatusDTO',
    method: 'POST',
    data
  })
}
// 获取code列表
export function queryConversionCodeList (params) {
  return request({
    url: '/code/queryConversionCodeList',
    method: 'GET',
    params
  })
}
// 新增产品解锁码
export function addConversionCode (data) {
  return request({
    url: '/code/addConversionCode',
    method: 'POST',
    data
  })
}
// 获取订单列表
export function getTbOrderList (params) {
  return request({
    url: '/order/getTbOrderList',
    method: 'GET',
    params
  })
}
// 获取订单详情
export function getTbOrderInfo (params) {
  return request({
    url: '/order/getTbOrderInfo',
    method: 'GET',
    params
  })
}
// 提交快递信息
export function fillInTheTrackingNumber (data) {
  return request({
    url: '/order/fillInTheTrackingNumber',
    method: 'POST',
    data
  })
}
// 确认收货
export function executionOrderCompletion (data) {
  return request({
    url: '/order/executionOrderCompletion',
    method: 'POST',
    data
  })
}
// 刷新物流信息
export function queryExpress (params) {
  return request({
    url: '/order/queryExpress',
    method: 'GET',
    params
  })
}
// 获取物流信息
export function queryTrackKd (params) {
  return request({
    url: '/order/queryTrackKd',
    method: 'GET',
    params
  })
}

// 获取用户订单
export function queryTbOrderList (params) {
  return request({
    url: '/order/queryTbOrderList',
    method: 'GET',
    params
  })
}
// 根据订单号查询订单下产品
export function queryProductOrderList (params) {
  return request({
    url: '/orderProduct/queryTbOrderList',
    method: 'GET',
    params
  })
}
// 获取报名资格
export function queryTbEnrollmentList (params) {
  return request({
    url: '/enrollment/queryTbEnrollmentList',
    method: 'GET',
    params
  })
}
// 判断城市半是否可以签到
export function checkSetPromotionNum (params) {
  return request({
    url: '/cityMatch/half/checkSetPromotionNum',
    method: 'GET',
    params
  })
}
// 一键生成号码牌
export function createCompetitionNumberList (data) {
  return request({
    url: '/enrollment/createCompetitionNumberList',
    method: 'POST',
    data
  })
}
// 重置号码牌
export function resetCompetitionNumberList (data) {
  return request({
    url: '/enrollment/resetCompetitionNumberList',
    method: 'POST',
    data
  })
}
// 签到
export function citySignIn (data) {
  return request({
    url: '/enrollment/signIn',
    method: 'POST',
    data
  })
}

// 根据赛季和赛程Flag获取赛程信息
export function querySeasonRaceScheduleFlag (params) {
  return request({
    url: '/raceSchedule/querySeasonRaceScheduleFlag',
    method: 'GET',
    params
  })
}
// 根据产品Id查询产品开通记录
export function queryOpenProductById (data) {
  return request({
    url: '/openProduct/queryOpenProductById',
    method: 'POST',
    data
  })
}
// 获取线上考试成绩
export function queryAnswerResultsPage (params) {
  return request({
    url: '/Initial/Reply/queryAnswerResultsPage',
    method: 'GET',
    params
  })
}
// 获取线下考试成绩
export function queryPaperResultScore (params) {
  return request({
    url: '/Initial/Reply/queryPaperResultScore',
    method: 'GET',
    params
  })
}
// 获取成绩详情
export function getAnswerRaceScheduleByUserId (params) {
  return request({
    url: '/Initial/Reply/getAnswerRaceScheduleByUserId',
    method: 'GET',
    params
  })
}

// 比赛设置晋级
export function replyQueryModelPaperResultScore (data) {
  return request({
    url: '/Initial/Reply/queryModelPaperResultScore',
    method: 'POST',
    data
  })
}
// 线上成绩设置晋级
export function setTbEnrollmentPromotion (data) {
  return request({
    url: '/enrollment/setTbEnrollmentPromotion',
    method: 'POST',
    data
  })
}
// 线下成绩设置晋级
export function updateInitialReplyScore (data) {
  return request({
    url: '/Initial/Reply/updateInitialReplyScore',
    method: 'POST',
    data
  })
}
// 获取线上晋级预期
export function queryModelPaperExpectResultScore (params) {
  return request({
    url: '/Initial/Reply/queryModelPaperExpectResultScore',
    method: 'GET',
    params
  })
}
// 获取线下晋级预期
export function queryModelExpectInitialReplyScore (params) {
  return request({
    url: '/Initial/Reply/queryModelExpectInitialReplyScore',
    method: 'GET',
    params
  })
}
// 线上批量设置晋级
export function queryModelPaperResultScore (data) {
  return request({
    url: '/Initial/Reply/queryModelPaperResultScore',
    method: 'POST',
    data
  })
}
// 线下批量设置晋级
export function queryModelInitialReplyScore (data) {
  return request({
    url: '/Initial/Reply/queryModelInitialReplyScore',
    method: 'POST',
    data
  })
}

// 一键生成成绩查询码
export function oneKeyGenerateScoreCode (data) {
  return request({
    url: '/Initial/Reply/oneKeyGenerateScoreCode',
    method: 'POST',
    data
  })
}
// 单个生成成绩查询码
export function oneGenerateScoreCode (data) {
  return request({
    url: '/Initial/Reply/oneGenerateScoreCode',
    method: 'POST',
    data
  })
}
// 成绩模板下载
export function importPreliminaryCompetitionTemplate (params) {
  return request({
    url: '/Initial/Reply/importPreliminaryCompetitionTemplate',
    method: 'GET',
    params
  })
}
// 获取赛程时间列表
export function querySeasonRaceSchedule (params) {
  return request({
    url: '/raceSchedule/querySeasonRaceSchedule',
    method: 'GET',
    params
  })
}
// 设置当前赛程相关信息
export function setSeasonRaceSchedule (data) {
  return request({
    url: '/raceSchedule/setSeasonRaceSchedule',
    method: 'POST',
    data
  })
}
// 设置当前正在进行的赛程
export function setSeasonRaceScheduleIng (data) {
  return request({
    url: '/raceSchedule/setSeasonRaceScheduleIng',
    method: 'POST',
    data
  })
}

// ==============字库管理=============
// 获取题型列表
export function questionTypeList (params) {
  return request({
    url: '/erp/questionType/list',
    method: 'GET',
    params
  })
}
// 新增题型
export function addQuestionType (data) {
  return request({
    url: '/erp/questionType/save',
    method: 'POST',
    data
  })
}
// 删除题型
export function deleteQuestionType (id) {
  return request({
    url: `/erp/questionType/delete/${id}`,
    method: 'DELETE'
  })
}
// 获取题型详情
export function getQuestionType (questionId) {
  return request({
    url: `/erp/questionType/info/${questionId}`,
    method: 'GET'
  })
}
// 获取题列表
export function getQuestionList (params) {
  return request({
    url: '/erp/question/page',
    method: 'GET',
    params
  })
}
// 删除题
export function deleteQuestionData (id) {
  return request({
    url: `/erp/question/delete/${id}`,
    method: 'DELETE'
  })
}
// 获取题详情
export function getQuestionDetail (id) {
  return request({
    url: `/erp/question/info/${id}`,
    method: 'GET'
  })
}
// 保存题
export function saveQuestion (data) {
  return request({
    url: '/erp/question/save',
    method: 'POST',
    data
  })
}
// 获取试卷列表
export function getQuestionPaper (params) {
  return request({
    url: '/erp/questionPaper/page',
    method: 'GET',
    params
  })
}
// 获取试卷详情
export function getExamDetail (id) {
  return request({
    url: `/erp/questionPaper/info/${id}`,
    method: 'GET'
  })
}
// 删除试卷
export function deleteExamData (id) {
  return request({
    url: `/erp/questionPaper/delete/${id}`,
    method: 'DELETE'
  })
}
// 新建试卷
export function reatedExamtional (data) {
  return request({
    url: '/erp/questionPaper/create',
    method: 'POST',
    data
  })
}

// 修改试卷
export function saveChangeExamtional (data) {
  return request({
    url: '/erp/questionPaper/update',
    method: 'POST',
    data
  })
}
// 保存试卷中的题型
export function saveQuestionType (data) {
  return request({
    url: '/erp/questionPaper/updateQuestionType',
    method: 'POST',
    data
  })
}
// 根据题型选择保存试题
export function createBathByQuestionType (data) {
  return request({
    url: '/erp/questionPaperDetail/createBathByQuestionType',
    method: 'POST',
    data
  })
}

// 获取试卷中的试题
export function getQuestionPaperDetail (params) {
  return request({
    url: '/erp/questionPaperDetail/page',
    method: 'GET',
    params
  })
}

// 获取字库列表
export function getCWordList (params) {
  return request({
    url: '/lexicon/getCWordList',
    method: 'GET',
    params
  })
}
// 删除字
export function deleteCWord (params) {
  return request({
    url: '/lexicon/deleteCWord',
    method: 'DELETE',
    params
  })
}
// 增加or修改字库内容
export function addCWord (data) {
  return request({
    url: '/lexicon/addCWord',
    method: 'POST',
    data
  })
}
// 查询字详情
export function getCWordInfoGroupList (params) {
  return request({
    url: '/lexicon/getCWordInfoById',
    method: 'GET',
    params
  })
}
// 新增赛区
export function saveOrUpdateDistrict (data) {
  return request({
    url: '/erp/district/saveOrUpdateDistrict',
    method: 'POST',
    data
  })
}
// 查询erp列表用户
export function queryErpUserList (params) {
  return request({
    url: '/erp/queryErpUserList',
    method: 'GET',
    params
  })
}
// 获取赛区事件列表
export function queryDistrictTimePage (params) {
  return request({
    url: '/examinationSchool/queryPage',
    method: 'GET',
    params
  })
}
// 保存或修改赛区时间
export function saveOrUpdateDistrictTime (data) {
  return request({
    url: '/erp/districtTime/v1/saveOrUpdateDistrictTime',
    method: 'POST',
    data
  })
}
// 删除赛区时间
export function deleteDistrictTime (params) {
  return request({
    url: '/erp/districtTime/v1/deleteDistrictTime',
    method: 'GET',
    params
  })
}
// 获取课程产品列表
export function getTbProductListInfoPage (params) {
  return request({
    url: '/lexicon/getTbProductListInfoPage',
    method: 'GET',
    params
  })
}
// 删除课程
export function deleteProductInfo (data) {
  return request({
    url: '/lexicon/deleteProductInfo',
    method: 'POST',
    data
  })
}
// 下架处理
export function updateTakeOffTheShelf (data) {
  return request({
    url: '/lexicon/updateTakeOffTheShelf',
    method: 'POST',
    data
  })
}

// 获取课程二级联动类型
export function secondaryLinkageCourseQuery (data) {
  return request({
    url: '/lexicon/secondaryLinkageCourseQuery',
    method: 'POST',
    data
  })
}

// 新增课程产品
export function addTbProductListInfo (data) {
  return request({
    url: '/lexicon/addTbProductListInfo',
    method: 'POST',
    data
  })
}
// 获取课程详情
export function queryTbProductListInfo (params) {
  return request({
    url: '/lexicon/queryTbProductListInfo',
    method: 'GET',
    params
  })
}
// 根据学校获取学校下的老师
export function getSchoolTeacherList (params) {
  return request({
    url: '/school/getSchoolTeacherList',
    method: 'GET',
    params
  })
}

// 学校开通课程
export function addTbProductSchoolList (data) {
  return request({
    url: '/openProduct/addTbProductSchoolList',
    method: 'POST',
    data
  })
}
// 查询学校开通了哪些课程
export function queryProductSchoolList (params) {
  return request({
    url: '/openProduct/queryProductSchoolList',
    method: 'GET',
    params
  })
}
// 添加社团课老师信息
export function addSchoolTeacherOrganization (data) {
  return request({
    url: '/school/addSchoolTeacherOrganization',
    method: 'POST',
    data
  })
}
// 下载喜报模板
export function downLoad (params) {
  return request({
    url: '/goodNews/downLoad',
    method: 'GET',
    params
  })
}
// 导入喜报模板
export function importExcel (data) {
  return request({
    url: '/goodNews/importExcel',
    method: 'POST',
    data
  })
}
// 一键拉取喜报数据
export function goodNewsNationalData (data) {
  return request({
    url: '/goodNews/nationalData',
    method: 'POST',
    data
  })
}

// 社团管理，编辑授课老师信息
export function updateSchoolTeacher (data) {
  return request({
    url: '/school/updateSchoolTeacher',
    method: 'POST',
    data
  })
}

// 删除社团老师课程
export function deleteSchoolTeacher (data) {
  return request({
    url: '/school/deleteSchoolTeacher',
    method: 'POST',
    data
  })
}

// 解封和禁用教师课程
export function disableSchoolTeacher (data) {
  return request({
    url: '/school/disableSchoolTeacher',
    method: 'POST',
    data
  })
}

// 线上线下成绩修改
export function updateTbEnrollmentIsOnline (data) {
  return request({
    url: '/enrollment/updateTbEnrollmentIsOnline',
    method: 'POST',
    data
  })
}
// 重置选手线上初复赛成绩
export function resetPlayerScore (data) {
  return request({
    url: '/reset/resetPlayerScore',
    method: 'POST',
    data
  })
}
// 重置IBC选手一轮成绩
export function resetMatchScore (data) {
  return request({
    url: '/ibc/matchScore/resetMatchScore',
    method: 'POST',
    data
  })
}
// 查询线下考场考位
export function queryExaminationListPage (params) {
  return request({
    url: '/examinationRoom/queryExaminationListPage',
    method: 'GET',
    params
  })
}

// 设置是否开放考场
export function setExaminationTestPosition (data) {
  return request({
    url: '/examinationRoom/setExaminationTestPosition',
    method: 'POST',
    data
  })
}

// 新增考场
export function addExaminationRoom (data) {
  return request({
    url: '/examinationRoom/create',
    method: 'POST',
    data
  })
}
// 修改考场
export function updateExaminationRoom (data) {
  return request({
    url: '/examinationRoom/edit',
    method: 'POST',
    data
  })
}
// 查询考场内的学生
export function queryExaminationUserListPage (params) {
  return request({
    url: '/examinationUser/page',
    method: 'GET',
    params
  })
}
// 重置考场内所有学生
export function resetAllUser (data) {
  return request({
    url: '/examinationUser/removeExaminationRoomAllUser',
    method: 'POST',
    data
  })
}
// 重置考场内的某个学生
export function resetSingleUser (data) {
  return request({
    url: '/examinationUser/removeExaminationRoomUser',
    method: 'POST',
    data
  })
}
// 根据手机号重置选手信息
export function removeExaminationRoomUserByPhone (data) {
  return request({
    url: '/examinationUser/removeExaminationRoomUserByPhone',
    method: 'POST',
    data
  })
}
// 查询本组别未选择考场【线下-线上】学生
export function queryUnselectedRoomUserList (params) {
  return request({
    url: '/examinationUser/queryUnselectedRoomUserList',
    method: 'GET',
    params
  })
}
// 批量添加
export function batchExaminationRoom (data) {
  return request({
    url: '/examinationUser/addUser',
    method: 'POST',
    data
  })
}
// 个人赛赛务管理-首页
// 个人赛赛程-统计人数
export function individualScheduleStatistics (params) {
  return request({
    url: '/homePage/individualScheduleStatistics',
    method: 'GET',
    params
  })
}
// 数据统计
export function dataStatistics (params) {
  return request({
    url: '/homePage/dataStatistics',
    method: 'GET',
    params
  })
}
// 待处理事务
export function pendingMatter (params) {
  return request({
    url: '/homePage/pendingMatter',
    method: 'GET',
    params
  })
}
// 个人赛考务管理-首页
// 个人赛成绩查询（本赛季）
export function competitionStatisticsBySeason (params) {
  return request({
    url: '/examination/competitionStatisticsBySeason',
    method: 'GET',
    params
  })
}
// 历史成绩查询
export function historicalCompetitionStatistics (params) {
  return request({
    url: '/examination/historicalCompetitionStatistics',
    method: 'GET',
    params
  })
}
// 个人赛参赛人数统计
export function competitionBySeason (params) {
  return request({
    url: '/examination/competitionBySeason',
    method: 'GET',
    params
  })
}
// 历史参赛人数统计
export function historicalGameStatistics (params) {
  return request({
    url: '/examination/historicalGameStatistics',
    method: 'GET',
    params
  })
}
// 订单概览
export function historicalProductStatistics (params) {
  return request({
    url: '/product/historicalProductStatistics',
    method: 'GET',
    params
  })
}

// 标签-查询系统预设标签
export function queryWordTagList (params) {
  return request({
    url: '/sWord/queryWordTagList',
    method: 'GET',
    params
  })
}

// 标签-分页列表
export function queryWordTagInfoList (params) {
  return request({
    url: '/sWord/queryWordTagInfoList',
    method: 'GET',
    params
  })
}

// 添加|修改标签
export function updateWordTagInfoList (data) {
  return request({
    url: '/sWord/updateWordTagInfoList',
    method: 'POST',
    data
  })
}

// 删除标签
export function deleteWordTagInfoList (id) {
  return request({
    url: `/sWord/deleteWordTagInfoList/${id}`,
    method: 'DELETE'
  })
}

// 获取单词列表
export function getWordList (params) {
  return request({
    url: '/erp/word/page',
    method: 'GET',
    params
  })
}

// 获取单词详情
export function getWordDetailData (id) {
  return request({
    url: `/erp/word/info/${id}`,
    method: 'GET'
  })
}

// 保存单词详情
export function saveWordDetail (data) {
  return request({
    url: '/erp/word/save',
    method: 'POST',
    data
  })
}
// 批量导出单词列表
export function WordListExport (params) {
  return request({
    url: '/noAuth/export',
    method: 'GET',
    params
  })
}
// 获取单词详情
export function wordThesaurusList (params) {
  return request({
    url: '/wordThesaurus/wordPage',
    method: 'GET',
    params
  })
}

// 获取词库列表
export function getWordGroupList (params) {
  return request({
    url: '/wordThesaurus/page',
    method: 'GET',
    params
  })
}
// 获取词库详情
export function getWordGroupDetailData (id) {
  return request({
    url: `wordThesaurus/info/${id}`,
    method: 'GET'
  })
}
// 修改词库
export function saveWordGroupDetail (data) {
  return request({
    url: '/wordThesaurus/update',
    method: 'POST',
    data
  })
}
// 新增词库
export function addWordGroupDetail (data) {
  return request({
    url: '/wordThesaurus/create',
    method: 'POST',
    data
  })
}
// 修改词库内单词状态
export function updateWordThesaurusState (data) {
  return request({
    url: '/wordThesaurus/updateJoinState',
    method: 'POST',
    data
  })
}
// 保存词库内单词内容
export function saveWordListData (data) {
  return request({
    url: '/wordThesaurus/saveWord',
    method: 'POST',
    data
  })
}
// 获取用户下的身份列表
export function getSysUserIdentityList (params) {
  return request({
    url: '/erpSysUserIdentity/list',
    method: 'GET',
    params
  })
}
// 修改用户身份
export function erpEditSysUserIdentity (data) {
  return request({
    url: '/erpSysUserIdentity/edit',
    method: 'POST',
    data
  })
}
// 删除用户身份
export function erpDeleteSysUserIdentity (data) {
  return request({
    url: '/erpSysUserIdentity/delete',
    method: 'DELETE',
    data
  })
}

// 获取系统用户列表
export function getQueryErpUserList (params) {
  return request({
    url: '/erp/queryErpUserList',
    method: 'GET',
    params
  })
}
// 新增系统用户信息
export function addSystemUser (data) {
  return request({
    url: '/erp/add',
    method: 'POST',
    data
  })
}
// 修改系统用户信息
export function editSystemUser (data) {
  return request({
    url: '/erp/edit',
    method: 'POST',
    data
  })
}
// 查询系统用户详情
export function getSysUserinfo (id) {
  return request({
    url: `/erp/info/${id}`,
    method: 'GET'
  })
}
// 获取部门列表
export function getSectionList (params) {
  return request({
    url: '/dept/tree',
    method: 'GET',
    params
  })
}
// 编辑部门信息
export function editDeptData (data) {
  return request({
    url: '/dept/edit',
    method: 'POST',
    data
  })
}
// 删除部门信息
export function deleteSysUserinfo (id) {
  return request({
    url: `/dept/delete/${id}`,
    method: 'DELETE'
  })
}

// 添加部门
export function createDeptData (data) {
  return request({
    url: '/dept/create',
    method: 'POST',
    data
  })
}
// 获取部门下的赛区
export function getDeptDistrict (params) {
  return request({
    url: '/dept/getDeptDistrict',
    method: 'GET',
    params
  })
}
// 保存部门下的赛区
export function saveEditDistrict (data) {
  return request({
    url: '/dept/editDistrict',
    method: 'POST',
    data
  })
}

// 获取岗位列表
export function getRoleList (params) {
  return request({
    url: '/role/page',
    method: 'GET',
    params
  })
}
// 获取岗位列表不分页
export function getRoleListData (params) {
  return request({
    url: '/role/list',
    method: 'GET',
    params
  })
}

// 修改岗位
export function editRoleData (data) {
  return request({
    url: '/role/edit',
    method: 'POST',
    data
  })
}
// 删除岗位
export function deleteRoleinfo (id) {
  return request({
    url: `/role/delete/${id}`,
    method: 'DELETE'
  })
}
// 新建岗位
export function creatRoleData (data) {
  return request({
    url: '/role/create',
    method: 'POST',
    data
  })
}
// 添加用户身份
export function erpSysUserIdentityCreate (data) {
  return request({
    url: '/erpSysUserIdentity/create',
    method: 'POST',
    data
  })
}

// 获取用户身份列表
export function listByLogin (params) {
  return request({
    url: '/erpSysUserIdentity/listByLogin',
    method: 'GET',
    params
  })
}

// 设置当前登录身份
export function setIdentity (params) {
  return request({
    url: '/erp/setIdentity',
    method: 'GET',
    params
  })
}

// 获取字典列表
export function getDictTypeList (params) {
  return request({
    url: '/dict/page',
    method: 'GET',
    params
  })
}

// 创建字典类型
export function addSysDictType (data) {
  return request({
    url: '/dict/create',
    method: 'POST',
    data
  })
}

// 修改字典类型
export function editSysDictType (data) {
  return request({
    url: '/dict/edit',
    method: 'POST',
    data
  })
}

// 删除字典信息
export function deleteSysDictType (data) {
  return request({
    url: '/dict/delete',
    method: 'DELETE',
    data
  })
}

// 获取字典键值列表
export function getSysDictDataList (params) {
  return request({
    url: '/dictData/page',
    method: 'GET',
    params
  })
}

// 修改字典键值对
export function editSysDictTypeValue (data) {
  return request({
    url: '/dictData/edit',
    method: 'POST',
    data
  })
}

// 新增字典键值对
export function addSysDictTypeValue (data) {
  return request({
    url: '/dictData/create',
    method: 'POST',
    data
  })
}
// 删除字典键值对
export function deleteSysDictTypeValue (data) {
  return request({
    url: '/dictData/delete',
    method: 'DELETE',
    data
  })
}

// 获取菜单树形图
export function getSysMenuTreeData (params) {
  return request({
    url: '/menu/tree',
    method: 'GET',
    params
  })
}
// 创建菜单
export function creatSysMenuTreeData (data) {
  return request({
    url: '/menu/create',
    method: 'POST',
    data
  })
}

// 删除菜单
export function deleteSysMenuTreeData (data) {
  return request({
    url: '/menu/delete',
    method: 'DELETE',
    data
  })
}
// 编辑菜单
export function editSysMenuTreeData (data) {
  return request({
    url: '/menu/edit',
    method: 'POST',
    data
  })
}
// 保存分配权限
export function saveSysMenuTreeData (data) {
  return request({
    url: '/roleMenu/allocation',
    method: 'POST',
    data
  })
}
// 获取树形权限包括已分配
export function getSelectMenuTreeData (params) {
  return request({
    url: '/roleMenu/tree',
    method: 'GET',
    params
  })
}

// 获取所有日志
export function getAllLogsList (params) {
  return request({
    url: '/sysLog/page',
    method: 'GET',
    params
  })
}

// 下载记录
export function downLoadList (params) {
  return request({
    url: '/export/page',
    method: 'GET',
    params
  })
}

// 消息记录
export function wordFeedbackList (params) {
  return request({
    url: '/wordFeedback/page',
    method: 'GET',
    params
  })
}
// 消息回复
export function wordFeedbackReply (data) {
  return request({
    url: '/wordFeedback/reply',
    method: 'POST',
    data
  })
}

// 复制词库
export function copyWordGrop (data) {
  return request({
    url: '/wordThesaurus/copy',
    method: 'POST',
    data
  })
}
// 获取团体赛报名信息
export function itsoRegisterList (params) {
  return request({
    url: '/itsoRegister/page',
    method: 'GET',
    params
  })
}
// 审批团体赛学校申请
export function itsoRegisterApprove (data) {
  return request({
    url: '/itsoRegister/approve',
    method: 'POST',
    data
  })
}
// 确认缴费
export function itsoRegisterPayment (data) {
  return request({
    url: '/itsoRegister/payment',
    method: 'POST',
    data
  })
}
// 删除图片回执
export function removeBackImg (data) {
  return request({
    url: '/erp/removeImg',
    method: 'DELETE',
    data
  })
}
// 退款-查看退款列表
export function orderRefundReviewList (params) {
  return request({
    url: '/refundRecord/page',
    method: 'GET',
    params
  })
}
// 获取退款详情
export function orderRefundReviewInfo (params) {
  return request({
    url: '/refundRecord/info',
    method: 'GET',
    params
  })
}
// 退款审批
export function refundRecordReview (data) {
  return request({
    url: '/refundRecord/review',
    method: 'POST',
    data
  })
}
// 退回赛区审批
export function refundRecordBackNode (data) {
  return request({
    url: '/refundRecord/backNode',
    method: 'POST',
    data
  })
}

// 获取开通产品列表
export function getProductListData (params) {
  return request({
    url: '/lexicon/getOpenProducts',
    method: 'GET',
    params
  })
}
// 开通产品
export function openProducts (data) {
  return request({
    url: '/lexicon/openProducts',
    method: 'POST',
    data
  })
}
// 修改用户erp状态
export function updateState (data) {
  return request({
    url: '/erp/updateState',
    method: 'POST',
    data
  })
}
// 获取产品标识
export function getFlagListData (params) {
  return request({
    url: '/lexicon/getFlagList',
    method: 'GET',
    params
  })
}
// 获取产品标识
export function getDetailFlagList (params) {
  return request({
    url: '/lexicon/getDetailFlagList',
    method: 'GET',
    params
  })
}
// 导出订单
export function excelTbOrderList (params) {
  return request({
    url: '/order/excelTbOrderList',
    method: 'GET',
    params
  })
}
// 修改兑换码分配状态
export function updateAllocation (data) {
  return request({
    url: '/code/updateAllocation',
    method: 'POST',
    data
  })
}
// 获取免初赛列表
export function enrollmentAvoidUserList (params) {
  return request({
    url: '/enrollmentAvoidUser/queryList',
    method: 'GET',
    params
  })
}

// 下载模板
export function downLoadTemplate (params) {
  return request({
    url: '/enrollmentAvoidUser/downLoadTemplate',
    method: 'GET',
    params
  })
}
export function deleteImportUser (data) {
  return request({
    url: '/enrollmentAvoidUser/delete',
    method: 'DELETE',
    data
  })
}
// 修改退款金额
export function changeOrderPrice (data) {
  return request({
    url: '/refundRecord/updateRefundProce',
    method: 'POST',
    data
  })
}

// 审核缴费
export function auditPayment (data) {
  return request({
    url: '/national/approve',
    method: 'POST',
    data
  })
}

// 导出线下考场
export function excelQueryExaminationListPage (params) {
  return request({
    url: '/examinationRoom/excelQueryExaminationListPage',
    method: 'GET',
    params
  })
}
// 下载模板
export function downLoadExaminationRoom (params) {
  return request({
    url: '/examinationRoom/downLoad',
    method: 'GET',
    params
  })
}
// 新增学校考场
export function examinationSchoolCreate (data) {
  return request({
    url: '/examinationSchool/create',
    method: 'POST',
    data
  })
}

// 删除学校考场
export function examinationSchoolDelete (data) {
  return request({
    url: '/examinationSchool/delete',
    method: 'DELETE',
    data
  })
}
// 获取学校时间
export function v1queryDistrictTimePage (params) {
  return request({
    url: '/erp/districtTime/v1/queryDistrictTimePage',
    method: 'GET',
    params
  })
}

// 拉取线下成绩
export function createInitialReplyScore (data) {
  return request({
    url: '/Initial/Reply/createInitialReplyScore',
    method: 'POST',
    data
  })
}

// 获取参赛码
export function getSemiGamecode (params) {
  return request({
    url: '/formMatchCode/page',
    method: 'GET',
    params
  })
}

// 新增参赛码
export function saveSemiGamecode (data) {
  return request({
    url: '/formMatchCode/save',
    method: 'POST',
    data
  })
}

// 修改参赛码
export function updateSemiGamecode (data) {
  return request({
    url: '/formMatchCode/update',
    method: 'POST',
    data
  })
}

// 删除参赛码
export function formMatchCodeDelete (data) {
  return request({
    url: '/formMatchCode/delete',
    method: 'DELETE',
    data
  })
}

// 获取表单答题
export function queryTheOnlineFormAnswer (params) {
  return request({
    url: '/formMatchResult/getPage',
    method: 'GET',
    params
  })
}
// 生成表单答题二维码
export function createFormQrCodeImage (params) {
  return request({
    url: '/formMatchCode/createQrCodeImage',
    method: 'GET',
    params
  })
}

// 匹配系统中信息
export function formMatchResultMate (data) {
  return request({
    url: '/formMatchResult/mate',
    method: 'POST',
    data
  })
}
// 解绑
export function formMatchResultUnbinding (data) {
  return request({
    url: '/formMatchResult/unbinding',
    method: 'POST',
    data
  })
}

// 一键匹配
export function oneClickMatchingFunction (data) {
  return request({
    url: '/formMatchResult/mateByDistrictId',
    method: 'POST',
    data
  })
}

// 团体赛申请匹配
export function itsoRegisterMatched (data) {
  return request({
    url: '/itsoRegister/matched',
    method: 'POST',
    data
  })
}
// 蜂计划产品开通信息
export function beePlanUserDataList (params) {
  return request({
    url: '/beePlanUser/page',
    method: 'GET',
    params
  })
}

// 表单答题批量设置晋级
export function formPromotionBatch (data) {
  return request({
    url: '/formMatchResult/promotionBatch',
    method: 'POST',
    data
  })
}

// 晋级结果预览
export function promotionBatchPreview (data) {
  return request({
    url: '/formMatchResult/promotionBatchPreview',
    method: 'POST',
    data
  })
}

// 团体赛俱乐部解绑
export function itsoRegisterDelMatched (data) {
  return request({
    url: '/itsoRegister/delMatched',
    method: 'POST',
    data
  })
}
// 获取俱乐部列表
export function itsoClubInfoList (params) {
  return request({
    url: '/itsoClubInfo/page',
    method: 'GET',
    params
  })
}

// 获取俱乐部详细信息
export function getClubInfoById (params) {
  return request({
    url: '/itsoClubInfo/getClubInfoById',
    method: 'GET',
    params
  })
}

// 修改会员学校状态
export function setSchoolMember (data) {
  return request({
    url: '/itsoClubInfo/setSchoolMember',
    method: 'POST',
    data
  })
}

// 取消会员学校
export function deleteSchoolMember (data) {
  return request({
    url: '/itsoClubInfo/deleteSchoolMember',
    method: 'DELETE',
    data
  })
}

// 设置俱乐部指导教师
export function setClubTeacher (data) {
  return request({
    url: '/itsoClubInfo/setClubTeacher',
    method: 'POST',
    data
  })
}

// 保存俱乐部信息
export function editErpClubInfo (data) {
  return request({
    url: '/itsoClubInfo/editErpClubInfo',
    method: 'POST',
    data
  })
}

// 获取俱乐部成员
export function getClubUserList (params) {
  return request({
    url: '/itsoClubUser/getClubUserList',
    method: 'GET',
    params
  })
}
// 新增俱乐部成员
export function addClubUser (params) {
  return request({
    url: '/itsoClubUser/addClubUser',
    method: 'GET',
    params
  })
}
// 审核加入俱乐部申请
export function updateStateData (params) {
  return request({
    url: '/itsoClubUser/updateState',
    method: 'GET',
    params
  })
}

// 移除申请俱乐部成员
export function deleteClubUser (data) {
  return request({
    url: '/itsoClubUser/delete',
    method: 'DELETE',
    data
  })
}

// 获取订单内容
export function indexStatistics (params) {
  return request({
    url: '/order/indexStatistics',
    method: 'GET',
    params
  })
}

// 获取俱乐部教师列表
export function getClubUserTeacherList (params) {
  return request({
    url: '/itsoClubUser/getClubUserTeacherList',
    method: 'GET',
    params
  })
}

// 审核俱乐部成员
export function clubUpdateState (data) {
  return request({
    url: '/itsoClubUser/updateState',
    method: 'POST',
    data
  })
}

// 获取俱乐部下战队列表
export function getListByClubId (params) {
  return request({
    url: '/itsoteam/getListByClubId',
    method: 'GET',
    params
  })
}

// 获取战队列表信息
export function getitsoTeamList (params) {
  return request({
    url: '/itsoteam/page',
    method: 'GET',
    params
  })
}

// 添加领队老师
export function addTeamTeacher (params) {
  return request({
    url: '/itsoClubUser/addTeamTeacher',
    method: 'GET',
    params
  })
}

// 开通组队资格
export function openTeamStatus (data) {
  return request({
    url: '/itsoteam/openTeamStatus',
    method: 'POST',
    data
  })
}

// 创建战队
export function createTeamData (data) {
  return request({
    url: '/itsoteam/create',
    method: 'POST',
    data
  })
}

// 编辑战队
export function editTeamData (data) {
  return request({
    url: '/itsoteam/update',
    method: 'POST',
    data
  })
}
// 删除战队
export function itsoteamDelete (data) {
  return request({
    url: '/itsoteam/delete',
    method: 'DELETE',
    data
  })
}
// 修改战队成员缴费状态
export function updateTeamPayd (data) {
  return request({
    url: '/itsoteam/updatePayd',
    method: 'POST',
    data
  })
}
// 获取未加入战队的成员
export function getNoTeamClubUserList (params) {
  return request({
    url: '/itsoClubUser/getNoTeamClubUserList',
    method: 'GET',
    params
  })
}

// 修改战队签到状态
export function changeTeamSignInState (data) {
  return request({
    url: '/itsoteam/signIn',
    method: 'POST',
    data
  })
}

// 查询优惠券列表
export function getCouponList (params) {
  return request({
    url: '/coupon/page',
    method: 'GET',
    params
  })
}

// 修改优惠券状态
export function updateCouponStatus (data) {
  return request({
    url: '/coupon/updateStatus',
    method: 'PUT',
    data
  })
}

// 新增优惠券
export function couponInsert (data) {
  return request({
    url: '/coupon/insert',
    method: 'POST',
    data
  })
}
// 获取优惠券详情
export function getCouponDetail (id) {
  return request({
    url: `/coupon/info/${id}`,
    method: 'GET'
  })
}

// 发放优惠券
export function couponIssue (data) {
  return request({
    url: '/coupon/issue',
    method: 'POST',
    data
  })
}

// 获取该优惠券发放人群
export function getfindUserCoupon (id) {
  return request({
    url: `/coupon/findUser/${id}`,
    method: 'GET'
  })
}

// 删除报名资格
export function removeQualificationQuest (data) {
  return request({
    url: '/enrollment/delete',
    method: 'DELETE',
    data
  })
}

// 转移报名资格
export function transferQualificationQuest (data) {
  return request({
    url: '/enrollment/transfer',
    method: 'POST',
    data
  })
}
// IBC获取默认赛季
export function queryIbcSeason (params) {
  return request({
    url: '/ibc/season/queryIbcSeason',
    method: 'GET',
    params
  })
}
// IBC获取轮次列表
export function getIbcSessionList (params) {
  return request({
    url: '/ibc/season/getIbcSessionList',
    method: 'GET',
    params
  })
}
// 获取轮次报名时间
export function getSeasonRegistrationStartAndEndTimes (params) {
  return request({
    url: '/ibc/season/getSeasonRegistrationStartAndEndTimes',
    method: 'GET',
    params
  })
}
// IBC修改轮次时间
export function updateIbcSeasonList (data) {
  return request({
    url: '/ibc/season/updateIbcSeasonList',
    method: 'POST',
    data
  })
}
// IBC修盖当前届、赛季
export function switchIbcSeason (data) {
  return request({
    url: '/ibc/season/switchIbcSeason',
    method: 'POST',
    data
  })
}

// 获取IBC报名信息
export function queryIbcTbEnrollmentPage (params) {
  return request({
    url: '/ibc/queryIbcTbEnrollmentPage',
    method: 'GET',
    params
  })
}

// 获取IBC金额和总人数
export function getIbcPurchaseNumPrice (params) {
  return request({
    url: '/ibc/queryIbcPurchaseNumPrice',
    method: 'GET',
    params
  })
}

// 获取ibc用户详情
export function queryIbcUserInfoById (params) {
  return request({
    url: '/ibc/queryIbcUserInfoById',
    method: 'GET',
    params
  })
}

// 修改or新增IBC用户
export function updateIbcUserInfo (data) {
  return request({
    url: '/ibc/updateIbcUserInfo',
    method: 'POST',
    data
  })
}

// erp 根据手机号获取用户详情
export function querySysUserByPhone (params) {
  return request({
    url: '/erpUser/querySysUserByPhone',
    method: 'GET',
    params
  })
}

// IBC获取房间列表
export function getIbcRoomList (params) {
  return request({
    url: '/ibc/room/getIbcRoomList',
    method: 'GET',
    params
  })
}

// IBC添加和修改房间
export function updateIbcRoom (data) {
  return request({
    url: '/ibc/room/updateIbcRoom',
    method: 'POST',
    data
  })
}
// 修改IBC房间信息
export function changeDateIbcRoom (data) {
  return request({
    url: '/ibc/room/changeDateIbcRoom',
    method: 'POST',
    data
  })
}
// IBC获取房间详情
export function getIbcRoomInfo (params) {
  return request({
    url: '/ibc/room/getIbcRoomInfo',
    method: 'GET',
    params
  })
}
// IBC删除房间信息
export function deleteIbcRoom (data) {
  return request({
    url: '/ibc/room/deleteIbcRoom',
    method: 'POST',
    data
  })
}

// 获取二三轮比赛信息
export function getIbcTwoThreeMatchList (params) {
  return request({
    url: '/ibc/matchScore/getIbcTwoThreeMatchList',
    method: 'GET',
    params
  })
}

// 获取未分配考生
export function getIbcSignUpUser (params) {
  return request({
    url: '/ibc/roomUser/getIbcSignUpUser',
    method: 'GET',
    params
  })
}
// 保存二三轮成绩点击事件
export function saveIbcMatchScoreList (data) {
  return request({
    url: '/ibc/matchScore/saveIbcMatchScoreList',
    method: 'POST',
    data
  })
}
// 删除二三轮成绩
export function deleteIbcTwoThreeMatchUser (data) {
  return request({
    url: '/ibc/matchScore/deleteIbcTwoThreeMatchUser',
    method: 'POST',
    data
  })
}
//  获取4/5轮列表信息
export function getPromotionRound (params) {
  return request({
    url: '/round/academic/judge/sspcn/getMatchInfo',
    // url: '/erp/ibc/matchRound/getPromotionRound',
    method: 'GET',
    params
  })
}
//  获取4/5轮统计信息
export function queryStatisticsNum (params) {
  return request({
    url: '/round/erp/ibc/matchRound/queryStatisticsNum',
    method: 'GET',
    params
  })
}

// 设置4-5轮晋级人数
export function setPromotionNum (params) {
  return request({
    url: '/round/erp/academic/judge/sspcn/setPromotionNumber',
    // url: '/erp/ibc/matchRound/setPromotionNum',
    method: 'GET',
    params
  })
}
// 比赛结束
export function finishTheGame (params) {
  return request({
    url: '/round/erp/finishTheGame',
    method: 'GET',
    params
  })
}
// 轮次回退
export function setFallback (data) {
  return request({
    url: '/round/erp/ibc/matchRound/setFallback',
    method: 'POST',
    data
  })
}
// 获取IBC淘汰排名信息
export function getPromotionNumber (params) {
  return request({
    url: '/round/erp/academic/judge/sspcn/getPromotionNumber',
    // url: '/erp/ibc/matchRound/setPromotionPlayer',
    method: 'GET',
    params
  })
}

// 4/5轮晋级确认
export function setPromotionPlayer (params) {
  return request({
    url: '/round/erp/academic/judge/sspcn/setPromotion',
    // url: '/erp/ibc/matchRound/setPromotionPlayer',
    method: 'GET',
    params
  })
}

// 4/5轮并列晋级确认
export function apposition (params) {
  return request({
    url: '/round/erp/academic/judge/sspcn/setPromotTied',
    // url: '/erp/ibc/matchRound/apposition',
    method: 'GET',
    params
  })
}
// IBC比赛回退
export function ibcResetMatch (params) {
  return request({
    url: '/round/erp/academic/judge/sspcn/resetMatch',
    method: 'GET',
    params
  })
}
// 查看实时排名
export function getRealTimeRanking (params) {
  return request({
    url: '/round/erp/academic/judge/sspcn/getRealTimeRanking',
    // url: '/erp/academic/judge/sspcn/getRealTimeRanking',
    method: 'GET',
    params
  })
}
// 重置所有轮次
export function resettingRound (params) {
  return request({
    url: '/round/academic/judge/sspcn/resetting',
    method: 'GET',
    params
  })
}
// 保存新增考生
export function addIbcTwoThreeMatch (data) {
  return request({
    url: '/ibc/matchScore/addIbcTwoThreeMatch',
    method: 'POST',
    data
  })
}

// 考场详情获取学生
export function getIbcRoomUser (params) {
  return request({
    url: '/ibc/roomUser/getIbcRoomUser',
    method: 'GET',
    params
  })
}

// 考场新增考生
export function addIbcRoomUser (data) {
  return request({
    url: '/ibc/roomUser/addIbcRoomUser',
    method: 'POST',
    data
  })
}
// 删除考场内考生
export function deleteIbcRoomUser (data) {
  return request({
    url: '/ibc/roomUser/deleteIbcRoomUser',
    method: 'POST',
    data
  })
}
// 移动考场内考生
export function moveIbcRoomUser (data) {
  return request({
    url: '/ibc/roomUser/moveIbcRoomUser',
    method: 'POST',
    data
  })
}

// 获取成绩列表数据
export function getIbcMatchPromotionList (params) {
  return request({
    url: '/ibc/matchScore/getIbcMatchPromotionList',
    method: 'GET',
    params
  })
}

// 获取晋级未晋级人数
export function getIbcPromotionNum (params) {
  return request({
    url: '/ibc/matchScore/getIbcPromotionNum',
    method: 'GET',
    params
  })
}

// 确认晋级名单
export function updateIbcMatchPromotionList (data) {
  return request({
    url: '/ibc/matchScore/updateIbcMatchPromotionList',
    method: 'POST',
    data
  })
}

// 发布成绩
export function saveIsPromotion (data) {
  return request({
    url: '/ibc/matchScore/saveIsPromotion',
    method: 'POST',
    data
  })
}

// 设置参赛人员
export function setMatchPlayer (data) {
  return request({
    url: 'erp/ibc/matchRound/setMatchPlayer',
    method: 'POST',
    data
  })
}

// 线下初赛成绩单项新增
export function addInitialReplyScore (data) {
  return request({
    url: '/Initial/Reply/addInitialReplyScore',
    method: 'POST',
    data
  })
}
// 获取城市半试卷（蜂talk）
export function getCityQuestionPaper (params) {
  return request({
    url: '/cityQuestionPaper/getPage',
    method: 'GET',
    params
  })
}

// 城市半题库 （蜂talk）
export function getCityQuestionWordList (params) {
  return request({
    url: '/cityQuestionWord/getPage',
    method: 'GET',
    params
  })
}
// 删除
export function deleteCityQuestionWord (data) {
  return request({
    url: '/cityQuestionWord/delete',
    method: 'DELETE',
    data
  })
}
// 清空
export function emptyCityQuestionWord (data) {
  return request({
    url: '/cityQuestionWord/empty',
    method: 'DELETE',
    data
  })
}
// 获取详情
export function cityQuestionWord (params) {
  return request({
    url: '/cityQuestionWord/info',
    method: 'GET',
    params
  })
}

// 团体赛获取轮次
export function itsoRefereeMatchGetPage (params) {
  return request({
    url: '/itsoRefereeMatchType/getPage',
    method: 'GET',
    params
  })
}

// 修改轮次信息
export function editItsoRefereeMatchType (data) {
  return request({
    url: '/itsoRefereeMatchType/edit',
    method: 'POST',
    data
  })
}
// 保存轮次信息
export function createItsoRefereeMatchType (data) {
  return request({
    url: '/itsoRefereeMatchType/create',
    method: 'POST',
    data
  })
}

// 删除轮次
export function deleteItsoRefereeMatchType (data) {
  return request({
    url: '/itsoRefereeMatchType/delete',
    method: 'DELETE',
    data
  })
}

// 获取轮次详情
export function itsoRefereeMatchGetInfo (params) {
  return request({
    url: '/itsoRefereeMatchType/info',
    method: 'GET',
    params
  })
}

// 查询可参赛战队列表
export function getRemainTeamList (data) {
  return request({
    url: '/itsoReferee/getRemainTeamList',
    method: 'POST',
    data
  })
}

// 查询参赛战队列表
export function getJoinMatchTeamList (data) {
  return request({
    url: '/itsoReferee/getJoinMatchTeamList',
    method: 'POST',
    data
  })
}

// 获取当前分组晋级策略及接口
export function getCurrentPromoPolicy (params) {
  return request({
    url: '/itsoReferee/getCurrentPromoPolicy',
    method: 'GET',
    params
  })
}

// 根据晋级策略生成小组信息
export function generateSeasonmatchGroupsByPolicy (data) {
  return request({
    url: '/itsoReferee/generateSeasonmatchGroupsByPolicy',
    method: 'POST',
    data
  })
}

// 预生成对战数据
export function createTeamMatch (data) {
  return request({
    url: '/itsoReferee/createTeamMatch',
    method: 'POST',
    data
  })
}

// 获取团体赛赛程数据
export function getItsoSeasonMatch (data) {
  return request({
    url: '/itsoReferee/getItsoSeasonMatch',
    method: 'POST',
    data
  })
}

// 按赛程获取参赛队数量
export function getTeamRefereeNum (params) {
  return request({
    url: '/itsoReferee/getTeamNum',
    method: 'GET',
    params
  })
}
// 设置战队为参赛队
export function setJinMatch (data) {
  return request({
    url: '/itsoReferee/joinMatch',
    method: 'POST',
    data
  })
}

// 移除参赛队
export function removeJoinMatch (data) {
  return request({
    url: '/itsoReferee/removeJoinMatch',
    method: 'POST',
    data
  })
}

// 重置参赛队
export function resetJoinMatch (data) {
  return request({
    url: '/itsoReferee/resetJoinMatch',
    method: 'POST',
    data
  })
}

// 重置分组策略
export function resetPromoPolicy (params) {
  return request({
    url: '/itsoReferee/resetPromoPolicy',
    method: 'GET',
    params
  })
}

// 批量生成对阵时间
export function updateTimeAndRoomNum (data) {
  return request({
    url: '/itsoReferee/updateTimeAndRoomNum',
    method: 'POST',
    data
  })
}

// 批量重置对阵时间
export function resetTimeAndRoomNum (data) {
  return request({
    url: '/itsoReferee/resetTimeAndRoomNum',
    method: 'POST',
    data
  })
}

// 单独编辑比赛时间
export function itsoRefereeUpdatetimeStr (data) {
  return request({
    url: '/itsoReferee/updatetimeStr',
    method: 'POST',
    data
  })
}
// 获取比分
export function getSeasonmatchScore (params) {
  return request({
    url: '/itsoReferee/getSeasonmatchScore',
    method: 'GET',
    params
  })
}
// 获取队员列表接口
export function getItsoUsers (params) {
  return request({
    url: '/itsoReferee/getItsoUsers',
    method: 'GET',
    params
  })
}

// 设置比分
export function submitTeamMatchScore (data) {
  return request({
    url: '/itsoReferee/submitTeamMatchScore',
    method: 'POST',
    data
  })
}
// 确认题型
export function setQuizSet (params) {
  return request({
    url: '/itsoReferee/quizSet',
    method: 'GET',
    params
  })
}

// 获取排兵布阵
export function getPlayersList (params) {
  return request({
    url: '/itsoReferee/getPlayersList',
    method: 'GET',
    params
  })
}

// 编辑比赛轮次
export function editMatchRound (data) {
  return request({
    url: '/itsoRefereeMatchType/editRound',
    method: 'POST',
    data
  })
}

// 编辑晋级策略轮次
export function editPromotionRuleNum (data) {
  return request({
    url: '/itsoRefereeMatchType/editPromotionRuleNum',
    method: 'POST',
    data
  })
}

// 城市总查询比赛所有轮次
export function setCityFinalRound (params) {
  return request({
    url: '/cityMatch/final/selectRound',
    method: 'GET',
    params
  })
}
// 全国总查询比赛所有轮次
export function setCityFinalRoundFinal (params) {
  return request({
    url: '/nationalMatch/selectRound',
    method: 'GET',
    params
  })
}
// 查询轮次下选手
export function selectUserByRound (params) {
  return request({
    url: '/cityMatch/final/selectUserByRound',
    method: 'GET',
    params
  })
}
// 全国总查询轮次下选手
export function selectUserByRoundFinal (params) {
  return request({
    url: '/nationalMatch/selectUserByRound',
    method: 'GET',
    params
  })
}
// 查询晋级人数
export function getPromotionNum (params) {
  return request({
    url: '/cityMatch/final/getPromotionNum',
    method: 'GET',
    params
  })
}
// 全国总查询晋级人数
export function getPromotionNumFinal (params) {
  return request({
    url: '/nationalMatch/getPromotionNum',
    method: 'GET',
    params
  })
}

// 设置晋级人数
export function citySetPromotionNum (data) {
  return request({
    url: '/cityMatch/final/setPromotionNum',
    method: 'POST',
    data
  })
}
// 全国总设置晋级人数
export function citySetPromotionNumFinal (data) {
  return request({
    url: '/nationalMatch/setPromotionNum',
    method: 'POST',
    data
  })
}
// 单项设置晋级
export function setPromotionPoint (data) {
  return request({
    url: '/cityMatch/final/setPromotion',
    method: 'POST',
    data
  })
}
// 国赛单项设置晋级
export function setPromotionPointFinal (data, hiddenLoad) {
  return request({
    url: '/nationalMatch/setPromotion',
    method: 'POST',
    hiddenLoad: hiddenLoad,
    data
  })
}
// 设置晋级
export function citySetPromotion (data) {
  return request({
    url: '/cityMatch/final/citySetPromotion',
    method: 'POST',
    data
  })
}
// 全国总设置晋级
export function citySetPromotionFinal (data) {
  return request({
    url: '/nationalMatch/citySetPromotion',
    method: 'POST',
    data
  })
}

// 并列晋级
export function setAbreastPromotion (data) {
  return request({
    url: '/cityMatch/final/setAbreastPromotion',
    method: 'POST',
    data
  })
}
// 国赛并列晋级
export function setAbreastPromotionFinal (data) {
  return request({
    url: '/nationalMatch/setAbreastPromotion',
    method: 'POST',
    data
  })
}
// 导出指定轮次下选手
export function excelUserByRound (params) {
  return request({
    url: '/cityMatch/final/excelUserByRound',
    method: 'GET',
    params
  })
}

// 回退上一轮次
export function resetMatch (data) {
  return request({
    url: '/cityMatch/final/matchBack',
    method: 'POST',
    data
  })
}
// 全国总回退上一轮次
export function resetMatchFinal (data) {
  return request({
    url: '/nationalMatch/matchBack',
    method: 'POST',
    data
  })
}
// 重置所有轮次
export function resetting (data) {
  return request({
    url: '/cityMatch/final/resetting',
    method: 'POST',
    data
  })
}
// 国赛重置所有轮次
export function resettingFinal (data) {
  return request({
    url: '/nationalMatch/resetting',
    method: 'POST',
    data
  })
}
// 查看签到选手列表
export function getSignInList (params) {
  return request({
    url: '/enrollment/getSignInList',
    method: 'GET',
    params
  })
}
// 查看实时排名
export function getMatchWinners (params) {
  return request({
    url: '/cityMatch/final/matchWinners',
    method: 'GET',
    params
  })
}
// 国赛查看实时排名
export function getMatchWinnersFinal (params) {
  return request({
    url: '/nationalMatch/matchWinners',
    method: 'GET',
    params
  })
}
// 获取城市赛淘汰排名
export function getPromotionRanking (params) {
  return request({
    url: '/cityMatch/final/getPromotionRanking',
    method: 'GET',
    params
  })
}
// 获取国赛赛淘汰排名
export function getPromotionRankingFinal (params) {
  return request({
    url: '/nationalMatch/getPromotionRanking',
    method: 'GET',
    params
  })
}
// 新增城市半题库
export function cityQuestionPaper (data) {
  return request({
    url: '/cityQuestionPaper/create',
    method: 'POST',
    data
  })
}
// 编辑城市半题库
export function editCityQuestionPaper (data) {
  return request({
    url: '/cityQuestionPaper/edit',
    method: 'POST',
    data
  })
}

// 网络总获取考场信息
export function getExamRoomDataInfo (params) {
  return request({
    url: '/examinationRoom/info',
    method: 'GET',
    params
  })
}
// 网络赛获取考场成员信息
export function getExamRoomMatchInfo (params) {
  return request({
    url: '/net/getExamRoomMatchInfo',
    method: 'GET',
    params
  })
}

// 网络赛保存答题结果
export function saveNetScores (data, hiddenLoad) {
  return request({
    url: '/net/saveScores',
    method: 'POST',
    hiddenLoad: hiddenLoad,
    data
  })
}
export function removeUserFromExamRoom (data) {
  return request({
    url: '/examinationUser/removeExaminationRoomUser',
    method: 'POST',
    data
  })
}
export function mergeExamRoom (data) {
  return request({
    url: '/net/mergeExamRoom',
    method: 'POST',
    data
  })
}

// 获取网络赛考场数量
export function getSetNumInfo (params) {
  return request({
    url: '/net/getSetNum',
    method: 'GET',
    params
  })
}

// 保存考场数量
export function saveExamRoomNum (data) {
  return request({
    url: '/net/setNum',
    method: 'POST',
    data
  })
}
// 国赛无组别开始比赛
export function nationalStart (data) {
  return request({
    url: '/nationalMatch/nationalStart',
    method: 'POST',
    data
  })
}
// 国赛无组别设置晋级
export function nationalNoGroupSetPromotion (data) {
  return request({
    url: '/nationalMatch/nationalNoGroupSetPromotion',
    method: 'POST',
    data
  })
}

// 国赛接口相关
// 国赛报名资格修改
export function ableApplyNationmatch (data) {
  return request({
    url: '/enrollment/ableApplyNationmatch',
    method: 'POST',
    data
  })
}

// 查看缴费信息
export function getNationmatchPayedInfo (params) {
  return request({
    url: '/national/getNationmatchPayedInfo',
    method: 'GET',
    params
  })
}

// 查看缴费详情
export function getNationmatchPayedDetails (params) {
  return request({
    url: '/national/getNationmatchPayedDetails',
    method: 'GET',
    params
  })
}

// 修改缴费详情
export function updateNationmatchPayedDetails (data) {
  return request({
    url: '/national/updateNationmatchPayedDetails',
    method: 'POST',
    data
  })
}
// 查询用户是否缴纳团体赛
export function getNationalItsoOrder (params) {
  return request({
    url: '/national/getNationalItsoOrder',
    method: 'GET',
    params
  })
}
// 批量修改资格
export function transferBatch (data) {
  return request({
    url: '/enrollment/transferBatch',
    method: 'POST',
    data
  })
}

// 确认缴费信息
export function updateNationmatchPayed (data) {
  return request({
    url: '/national/updateNationmatchPayed',
    method: 'POST',
    data
  })
}

// 国赛退费
export function deletedNationmatchPayedDetails (data) {
  return request({
    url: '/national/deletedNationmatchPayedDetails',
    method: 'POST',
    data
  })
}

// 获取国赛照片墙
export function nationalPictureWall (params) {
  return request({
    url: '/nationalPicture/page',
    method: 'GET',
    params
  })
}

// 审核照片
export function nationalPictureApprove (data) {
  return request({
    url: '/nationalPicture/approve',
    method: 'POST',
    data
  })
}

// 国赛物料、签到列表
export function getReportSignInList (params, hiddenLoad) {
  return request({
    url: '/national/getReportSignInList',
    method: 'GET',
    hiddenLoad: hiddenLoad,
    params
  })
}

// 获取个人签到数据
export function getReportSignInInfo (params) {
  return request({
    url: '/national/getReportSignInInfo',
    method: 'GET',
    params
  })
}

// 异常题列表
export function markPlayerQuestionErrorList (params) {
  return request({
    url: '/cityMatch/half/markPlayerQuestionErrorList',
    method: 'GET',
    params
  })
}

// 修改个人签到数据
export function editReportSignInInfo (data) {
  return request({
    url: '/national/editReportSignInInfo',
    method: 'POST',
    data
  })
}

// 生成指定赛程参赛资格
export function createQualification (data) {
  return request({
    url: '/enrollment/createQualification',
    method: 'POST',
    data
  })
}

// 修改订单时间
export function updateEndDate (data) {
  return request({
    url: '/order/updateEndDate',
    method: 'POST',
    data
  })
}

// 修改资格里面的年级和组别
export function updateLevel (data) {
  return request({
    url: '/enrollment/updateLevel',
    method: 'POST',
    data
  })
}

// 生成二维码
export function createQrCodeImage (params) {
  return request({
    url: '/school/createQrCodeImage',
    method: 'GET',
    params
  })
}

// 国赛复活赛大屏数据
export function getNationalScreenData (params) {
  return request({
    url: '/common/screen',
    method: 'GET',
    params
  })
}

// 新增复活赛手机号
export function resurrectionSignBrainless (data) {
  return request({
    url: '/nationalMatch/resurrection/resurrectionSignBrainless',
    method: 'POST',
    data
  })
}
// 获取拼词执行房间列表
export function getPczxRoomList (params) {
  return request({
    url: '/pczxRoom/getPage',
    method: 'GET',
    params
  })
}
// 获取拼词之星房间code
export function getRoomCode (params) {
  return request({
    url: '/pczxRoom/getRoomCode',
    method: 'GET',
    params
  })
}

// 新建拼词之星房间
export function createPczxRoom (data) {
  return request({
    url: '/pczxRoom/create',
    method: 'POST',
    data
  })
}

// 获取房间详情
export function getPczxRoominfo (params) {
  return request({
    url: '/pczxRoom/info',
    method: 'GET',
    params
  })
}

// 获取房间选手
export function getPczxRoomSit (params) {
  return request({
    url: '/pczxRoomSit/page',
    method: 'GET',
    params
  })
}

// 重置单个选手
export function removeSitById (data) {
  return request({
    url: '/pczxRoomSit/removeSitById',
    method: 'POST',
    data
  })
}

// 修改积分
export function updateIntegral (data) {
  return request({
    url: '/pczxRoomSit/updateIntegral',
    method: 'POST',
    data
  })
}

// 编辑保存考量
export function editPersonalPaperApplyInfo (data) {
  return request({
    url: '/paper/editPersonalPaperApplyInfo',
    method: 'POST',
    data
  })
}

// 修改蜂计划补卡券
export function updateFengPlanCard (data) {
  return request({
    url: '/beePlanUser/addCardNumberById',
    method: 'POST',
    data
  })
}

// 营地分页列表
export function camPage (params) {
  return request({
    url: '/camp/page',
    method: 'GET',
    params
  })
}

// 新增营地
export function campInsert (data) {
  return request({
    url: '/camp/insert',
    method: 'POST',
    data
  })
}

// 获取营地详情
export function campInfo (id) {
  return request({
    url: `/camp/info/${id}`,
    method: 'GET'
  })
}

// 编辑营地
export function campEdit (data) {
  return request({
    url: '/camp/edit',
    method: 'POST',
    data
  })
}

// 删除营地
export function campDel (id) {
  return request({
    url: `/camp/del/${id}`,
    method: 'DELETE'
  })
}

// 关闭-开启营地
export function campClose (data) {
  return request({
    url: '/camp/updateClose',
    method: 'POST',
    data
  })
}

// 获取营地内营员
export function campUserPage (params) {
  return request({
    url: 'campUser/page',
    method: 'GET',
    params
  })
}

// 新增营员
export function campUserInsert (data) {
  return request({
    url: '/campUser/insert',
    method: 'POST',
    data
  })
}

// 编辑营员信息
export function campUserEdit (data) {
  return request({
    url: '/campUser/edit',
    method: 'POST',
    data
  })
}

// 删除营地成员
export function campUserDel (id) {
  return request({
    url: `/campUser/del/${id}`,
    method: 'DELETE'
  })
}

// 导出营地成员
export function campUserExport (params) {
  return request({
    url: 'campUser/export',
    method: 'GET',
    params
  })
}

// 获取营地咨询列表
export function campConsultPage (params) {
  return request({
    url: 'campConsult/page',
    method: 'GET',
    params
  })
}

// 获取营地列表不分页
export function getNameList (params) {
  return request({
    url: 'camp/getNameList',
    method: 'GET',
    params
  })
}

// 新建咨询记录
export function campConsultCreate (data) {
  return request({
    url: '/campConsult/create',
    method: 'POST',
    data
  })
}

// 更新咨询客单状态
export function updateGuestStatus (data) {
  return request({
    url: '/campConsult/updateGuestStatus',
    method: 'POST',
    data
  })
}

// 新增跟踪记录
export function campTrackLogInsert (data) {
  return request({
    url: '/campTrackLog/insert',
    method: 'POST',
    data
  })
}

// 获取跟踪记录
export function campTrackLogList (params) {
  return request({
    url: 'campTrackLog/list',
    method: 'GET',
    params
  })
}

// 编辑跟踪记录
export function campTrackLogEdit (data) {
  return request({
    url: '/campTrackLog/edit',
    method: 'POST',
    data
  })
}

// 删除跟踪记录
export function campTrackLogDel (id) {
  return request({
    url: `/campTrackLog/del/${id}`,
    method: 'DELETE'
  })
}

// 消息推送相关
// 查询该赛区比赛时间下已推送服务号消息的线上参赛选手
export function getUserByPushServerNumber (params) {
  return request({
    url: 'erp/districtTime/v1/getUserByPushServerNumber',
    method: 'GET',
    params
  })
}

// 查询该赛区比赛时间下未推送服务号消息的参赛选手
export function getUserByNotPushServerNumber (params) {
  return request({
    url: 'erp/districtTime/v1/getUserByNotPushServerNumber',
    method: 'GET',
    params
  })
}

// 推送失败重新推送
export function pushByFalsePushServerNumber (data) {
  return request({
    url: '/erp/districtTime/v1/pushByFalsePushServerNumber',
    method: 'POST',
    data
  })
}

// 给未推送消息的推送
export function pushByNotPushServerNumber (data) {
  return request({
    url: '/erp/districtTime/v1/pushByNotPushServerNumber',
    method: 'POST',
    data
  })
}

// 给所有选手推送
export function pushServerNumberMatch (data) {
  return request({
    url: '/erp/districtTime/v1/pushServerNumberMatch',
    method: 'POST',
    data
  })
}

// 指定手机号推送
export function pushServerNumberMatchByPhoneList (data) {
  return request({
    url: '/erp/districtTime/v1/pushServerNumberMatchByPhoneList',
    method: 'POST',
    data
  })
}
// 指定选手id推送
export function pushServerNumberMatchByUserIdList (data) {
  return request({
    url: '/erp/districtTime/v1/pushServerNumberMatchByUserIdList',
    method: 'POST',
    data
  })
}
// 营地折扣成员列表
export function campUserCouponList (params) {
  return request({
    url: 'campUserCoupon/page',
    method: 'GET',
    params
  })
}

// 新增折扣成员
export function insertCampUserCoupon (data) {
  return request({
    url: '/campUserCoupon/insert',
    method: 'POST',
    data
  })
}

// 编辑折扣成员
export function editCampUserCoupon (data) {
  return request({
    url: '/campUserCoupon/edit',
    method: 'POST',
    data
  })
}

// 删除折扣成员
export function campUserCouponDel (id, data) {
  return request({
    url: `campUserCoupon/del/${id}`,
    method: 'POST',
    data
  })
}

// 获取所有学校列表信息
export function getAllSchoolList (params) {
  return request({
    url: 'common/getSchoolList',
    method: 'GET',
    params
  })
}
